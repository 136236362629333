import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import { Typography, Box } from "@mui/material";
import { fetchCPOs, selectAllCPOs } from "../../modules/cpo/cpoSlice";
import { Wrapper, PageContainer } from "./CpoStyles";
import { propOr, length } from "ramda";
import { useNavigate } from "react-router-dom";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import {
  renderAsCustom,
  renderAsLink,
} from "../../lib/components/Table/Cell/CellRenderers";
import ButtonLink from "../../lib/components/ButtonLink";
import { getValue } from "@testing-library/user-event/dist/utils";

import Skeleton from "@mui/material/Skeleton";

export const CPOs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CPOs = useSelector(selectAllCPOs);
  const cpoLoadingStatus = useSelector((state) => state.cpos.cpoLoadingStatus);

  useEffect(() => {
    dispatch(fetchCPOs());
  }, [dispatch]);
  const columns = [
    {
      label: "CPO Name",
      id: "cpo_name",
      accessor: "name",
      cell: ({ row }) => {
        return (
          <ButtonLink
            tooltipText={row.original?.name}
            value={row.original?.name}
            id={row.original.id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/cpos/${row.original.id}`);
            }}
            href={`/cpos/${row.original.id}`}
          />
        );
      },
    },
    {
      label: "CPO ID",
      id: "id",
      accessorFn: (row) => {
        let value = row?.id?.substring(0, 10) ? row?.id?.substring(0, 10) : "-";
        return value;
      },
    },
    {
      label: "Total Number of Stations",
      id: "charging_stations",
      cell: ({ row }) => {
        const totalStations = length(
          propOr([], "charging_stations", row.original)
        );
        if (totalStations > 0) {
          return (
            <ButtonLink
              tooltipText={totalStations}
              value={totalStations}
              id={row.original.id}
              textDecoration={"none"}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/stations`);
              }}
              href={`/stations`}
            />
          );
        } else {
          return totalStations;
        }
      },
      accessorFn: (row) => {
        const totalStations = length(propOr([], "charging_stations", row));
        return totalStations;
      },
      sortingFn: (rowA, rowB) => {
        const a = length(propOr([], "charging_stations", rowA.original));
        const b = length(propOr([], "charging_stations", rowB.original));
        if (a === 0 && b === 0) {
          return 0;
        } else if (a === 0) {
          return 1;
        } else if (b === 0) {
          return -1;
        }

        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
  ];

  const reactTableColumns = toReactTableColumns(columns);

  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          color: "#22093F",
          fontWeight: "bold",
          paddingTop: "30px",
        }}
      >
        Charge Point Operators
      </Typography>
      {cpoLoadingStatus === "Loading" ? (
        <Box sx={{ marginTop: "-8%" }}>
          <Skeleton animation="wave" width={"90%"} height={900} />
        </Box>
      ) : (
        <PageContainer>
          <GenericTable data={CPOs} columns={reactTableColumns} />
        </PageContainer>
      )}
    </Wrapper>
  );
};

import axios from "./axios";

const sendOtp = async (requestData, successCB, errorCB) => {
  const response = await axios.post("/1/admin/auth", requestData).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error);
      return error;
    }
  );
  return response;
};

const verifyOtp = async (requestData, successCB, errorCB) => {
  const response = await axios
    .post("/1/admin/auth/verify-otp", requestData)
    .then(
      (res) => {
        successCB(res.data);
        return res;
      },
      (error) => {
        errorCB(error);
        return error;
      }
    );
  return response;
};

// Super admin Login using google sso

const superAdminLogin = async (requestData, successCB, errorCB) => {
  const response = await axios
    .post("/1/admin/auth/verify-token", requestData)
    .then(
      (res) => {
        successCB(res.data);
        return res;
      },
      (error) => {
        errorCB(error);
        return error;
      }
    );
  return response;
};

export { sendOtp, verifyOtp, superAdminLogin };

import { Stack, Typography, Grid } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";

const PhoneNumberInputView = ({
    label,
    value,
    required,
    setValue,
    variant,
    styles,
    id,
    textAlignment,
    disabled,
    isError,
    defaultCountry,
    ...rest
}) => {

    return (
        <Stack direction={'column'} alignItems={'start'}>
            <Grid>
                <Typography sx={{color: '#4E3A65' ,fontSize: '16px'}}>
                    {label}
                </Typography>
            </Grid>
            <MuiPhoneNumber
            id={`phonenumber.${id}`}
            value={value}
            onChange={(value, country)=>{setValue(value, country)}}
            sx={styles || null}
            defaultCountry={defaultCountry || "us"}
            inputProps={{
                name: label,
                required: required,
            }}
            required={required}
            disabled={disabled}
            placeholder={'Phone Number'}
            variant="outlined"
            error={isError ? true : false}
            {...rest}
            >
            </MuiPhoneNumber>
        </Stack>
    )

}

export default PhoneNumberInputView

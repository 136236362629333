import React from "react";
import TextField from "@mui/material/TextField";

export const TextInputField = ({
  id,
  label,
  name,
  onSetItem,
  value,
  isRequired,
  isDisabled,
  fullWidth,
  multiline,
}) => {
  return (
    <TextField
      label={label}
      id={id}
      fullWidth={fullWidth}
      multiline={multiline}
      size="small"
      name={name}
      autoComplete="off"
      onChange={(e) => onSetItem(e.target.value)}
      value={value}
      required={isRequired}
      disabled={isDisabled}
    />
  );
};

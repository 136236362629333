import axios from "./axios";

export const getFleets = async () => {
  const response = await axios.get("/1/admin/fleets");
  return response;
};

export const getFleetsDetails = async (id) => {
  const response = await axios.get(`/1/admin/fleets/${id}`);
  return response;
};

export const getUserFleetsDetails = async (id) => {
  const response = await axios.get(`/1/admin/fleets/${id}/users`);
  return response;
};

export const createFleet = async (data, successCB, errorCB) => {
  return await axios.post(`/1/admin/fleets`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error);
      throw new Error(error.response);
    }
  );
};

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import { Typography, Box } from "@mui/material";
import {
  fetchChargingSessions,
  selectAllChargingSessions,
} from "../../modules/chargingSession/chargingSessionSlice";
import { Wrapper, PageContainer } from "./chargingSessionStyles";
import { format, parseISO } from "date-fns";
import { head, prop, propOr, equals } from "ramda";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import ButtonLink from "../../lib/components/ButtonLink";
import Skeleton from "@mui/material/Skeleton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

export const ChargingSessions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chargingSessions = useSelector(selectAllChargingSessions);
  const sessionLoadingStatus = useSelector(
    (state) => state.chargingSessions.sessionLoadingStatus
  );
  useEffect(() => {
    dispatch(fetchChargingSessions());
  }, [dispatch]);

  const cdrOrInterim = (row) => {
    let cdrReport;
    if (row.status === "COMPLETED") {
      cdrReport = row?.charging_session_reports?.find(
        (report) => report.type === "CDR"
      );
      return cdrReport;
    } else {
      cdrReport = row?.charging_session_reports?.find(
        (report) => report.type === "INTERIM"
      );
      return cdrReport;
    }
  };
  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const columns = [
    {
      label: "ID",
      accessor: "id",
      id: "id",
      cell: ({ row }) => {
        let value = row?.original.id?.substring(0, 10)
          ? row.original.id?.substring(0, 10)
          : "-";

        return (
          <ButtonLink
            tooltipText={row.original.id}
            value={value}
            id={row.original.id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/sessions/${row.original.id}`);
            }}
            href={`/sessions/${row.original.id}`}
          />
        );
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.id;
        const b = rowB.original.id;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Time Stamp",
      id: "start_time",
      filterFn: "datefilter",
      // enableGlobalFilter:true,

      accessorFn: (row) => {
        return row.start_time
          ? format(parseISO(row.start_time), "dd/MM/yyy HH:mm:ss")
          : "";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.start_time;
        const b = rowB.original.start_time;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },

    {
      label: "Charging Time",
      id: "total_charging_time",
      accessorFn: (row) => {
        let totalChargingTime = cdrOrInterim(row);
        return `${
          totalChargingTime &&
          totalChargingTime?.total_charging_time !== undefined
            ? secondsToHHMMSS(totalChargingTime?.total_charging_time)
            : "-"
        }`;
      },
    },
    {
      label: "Energy Dispensed",
      accessor: "total_energy_consumed",
      id: "total_energy_consumed",
      accessorFn: (row) => {
        let totalEnergyConsumed = cdrOrInterim(row);
        return `${
          totalEnergyConsumed &&
          totalEnergyConsumed?.total_energy_consumed !== undefined
            ? totalEnergyConsumed?.total_energy_consumed + " kWh"
            : "-"
        }`;
      },
    },
    {
      label: "Implied Power",
      id: "implied_energy",
      accessorFn: (row) => {
        let totalEnergyConsumed = cdrOrInterim(row);
        totalEnergyConsumed =
          totalEnergyConsumed &&
          totalEnergyConsumed?.total_energy_consumed !== undefined
            ? totalEnergyConsumed?.total_energy_consumed
            : "-";

        let chargingDuration = cdrOrInterim(row);
        chargingDuration =
          chargingDuration &&
          chargingDuration?.total_charging_time !== undefined
            ? chargingDuration?.total_charging_time
            : "-";

        let impliedEnergy;
        if (chargingDuration === 0) {
          impliedEnergy = 0;
        } else {
          impliedEnergy = totalEnergyConsumed / (chargingDuration / 3600);
          return `${
            equals(Number.NaN, impliedEnergy)
              ? "-"
              : impliedEnergy.toFixed(2) + " kW"
          }`;
        }
      },
    },
    {
      label: "Price",
      id: "total_price",
      accessorFn: (row) => {
        let sessionEnergyFee = propOr("-", "session_energy_fee", row);
        const cdrReport = cdrOrInterim(row);
        let totalAmount = cdrReport ? cdrReport.total_energy_consumed : "-";
        let price;
        if (sessionEnergyFee == "-" || totalAmount == "-") {
          price = "-";
        } else {
          price = sessionEnergyFee * totalAmount;
        }
        // return `${price == "-" ? "-" : price === 0 ? 0 : price.toFixed(2)}`;
        //   let formattedPrice = price == "-" ? "-" : price === 0 ? 0 : price.toFixed(2);
        //   return `$${formattedPrice}`;
        // },
        if (price !== "-") {
          price = `$ ${price === 0 ? 0 : price.toFixed(2)}`;
        }

        return price;
      },
    },
    {
      label: "CPO",
      id: "cpo",
      accessorFn: (row) => {
        let cpo = propOr(
          "-",
          "name",
          prop("cpo", prop("charging_station", prop("evse", row)))
        );
        return cpo;
      },
    },
    {
      label: "Status",
      id: "status",
      accessor: "status",
      accessorFn: (row) => {
        let status = propOr("-", "status", row);
        return status;
      },
    },
    {
      label: "Session Rating",
      id: "station_reviews",
      cell: ({ row }) => {
        const positive_review = propOr(
          "-",
          "positive_review",
          head(propOr([], "station_reviews", row.original))
        );
        return positive_review === true ? (
          <ThumbUpIcon />
        ) : positive_review === false ? (
          <ThumbDownIcon />
        ) : (
          "-"
        );
      },
      enableSorting: false,
    },
    {
      label: "Session Comments",
      id: "review",
      accessorFn: (row) => {
        let review = prop(
          "description",
          propOr("-", "review", head(propOr([], "station_reviews", row)))
        );
        return review;
      },
    },
    {
      label: "Receipt",
      id: "receipt",
      accessorFn: (row) => {
        let receiptId = prop("ReceiptID", row);
        return receiptId || "-";
      },
    },
  ];

  const reactTableColumns = toReactTableColumns(columns);

  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          color: "#22093F",
          fontWeight: "bold",
          paddingTop: "30px",
        }}
      >
        Charging Sessions
      </Typography>
      {sessionLoadingStatus === "Loading" ? (
        <Box sx={{ marginTop: "-8%" }}>
          <Skeleton animation="wave" width={"90%"} height={900} />
        </Box>
      ) : (
        <PageContainer>
          <GenericTable data={chargingSessions} columns={reactTableColumns} />
        </PageContainer>
      )}
    </Wrapper>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getChargingSessions,
  DetailChargingSessions,
  updateChargingSessionDetails,
} from "../../api/chargingSessions";

const initialState = {
  status: "idle",
  chargingSessions: [],
  detailChargingSession: "",
  sessionLoadingStatus: "",
  singleSessionLoadingStatus: "",
};

export const fetchChargingSessions = createAsyncThunk(
  "/fetchChargingSessions",
  async (userId) => {
    const response = await getChargingSessions(userId);
    return response.data;
  }
);

export const fetchDetailChargingSessions = createAsyncThunk(
  "/userChargingSessions",
  async (sessionId) => {
    const response = await DetailChargingSessions(sessionId);

    return response.data;
  }
);

export const updateDetailChargingSessions = createAsyncThunk(
  "/updateChargingSessions",
  async ({ id, data, successCB, errorCB }) => {
    const response = await updateChargingSessionDetails(
      id,
      data,
      successCB,
      errorCB
    );

    return response.data;
  }
);

const chargingSessionSlice = createSlice({
  name: "chargingSessions",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
    },
    clearSessionDetails: (state) => {
      state.detailChargingSession = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChargingSessions.pending, (state, action) => {
        state.status = "loading";
        state.sessionLoadingStatus = "Loading";
      })
      .addCase(fetchChargingSessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chargingSessions = action.payload.data;
        state.sessionLoadingStatus = "succeeded";
      })
      .addCase(fetchChargingSessions.rejected, (state, action) => {
        state.status = "failed";
        state.sessionLoadingStatus = "failed";
      })
      .addCase(fetchDetailChargingSessions.pending, (state, action) => {
        state.status = "loading";
        state.singleSessionLoadingStatus = "Loading";
      })
      .addCase(fetchDetailChargingSessions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.detailChargingSession = action.payload.data;
        state.singleSessionLoadingStatus = "succeeded";
      })
      .addCase(fetchDetailChargingSessions.rejected, (state, action) => {
        state.status = "failed";
        state.singleSessionLoadingStatus = "failed";
      })
      .addCase(updateDetailChargingSessions.pending, (state, action) => {
        state.status = "loading";
        state.singleSessionLoadingStatus = "Loading";
      })
      .addCase(updateDetailChargingSessions.fulfilled, (state, action) => {
        state.singleSessionLoadingStatus = "succeeded";
        state.detailChargingSession = action.payload.data;
      })
      .addCase(updateDetailChargingSessions.rejected, (state, action) => {
        state.status = "failed";
        state.singleSessionLoadingStatus = "failed";
      });
  },
});

export const { updateState, clearSessionDetails } =
  chargingSessionSlice.actions;

export default chargingSessionSlice.reducer;

export const selectAllChargingSessions = (state) => {
  return state.chargingSessions.chargingSessions;
};

export const selectDetailChargingSession = (state) => {
  return state.chargingSessions.detailChargingSession;
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserList,
  getUserDetails,
  updateUserDetails,
  updateUserDetailsFleet,
  createUserDetailsFleet,
} from "../../api/user";

const initialState = {
  status: "idle",
  users: [],
  userDetail: "",
  userLoadingStatus: "",
  singleUserLoadingStatus: "",
};

export const fetchUsers = createAsyncThunk("/fetchUsers", async () => {
  const response = await getUserList();
  return response?.data;
});

export const fetchUsersDetail = createAsyncThunk(
  "/fetchUsersDetail",
  async (userId) => {
    const response = await getUserDetails(userId);
    return response?.data;
  }
);

export const updateUsersDetail = createAsyncThunk(
  "/updateUserDetails",
  async ({ userId, data, successCB, errorCB }) => {
    const response = await updateUserDetails(userId, data, successCB, errorCB);

    return response?.data;
  }
);

export const updateUserDetailFleet = createAsyncThunk(
  "/updateUserDetailFleet",
  async ({ userId, data, successCB, errorCB }) => {
    const response = await updateUserDetailsFleet(
      userId,
      data,
      successCB,
      errorCB
    );

    return response?.data;
  }
);

export const addUserDetailFleet = createAsyncThunk(
  "/addUserDetailFleet",
  async ({ userId, data, successCB, errorCB }) => {
    const response = await createUserDetailsFleet(
      userId,
      data,
      successCB,
      errorCB
    );

    return response?.data;
  }
);
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
    },
    clearUserDetails: (state) => {
      state.userDetail = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = "loading";
        state.userLoadingStatus = "Loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userLoadingStatus = "succeeded";

        state.users = action.payload?.data;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.userLoadingStatus = "failed";
      })
      .addCase(fetchUsersDetail.pending, (state, action) => {
        state.status = "loading";
        state.singleUserLoadingStatus = "Loading";
      })
      .addCase(fetchUsersDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetail = action.payload?.data;
        state.singleUserLoadingStatus = "succeeded";
      })
      .addCase(fetchUsersDetail.rejected, (state, action) => {
        state.status = "failed";
        state.singleUserLoadingStatus = "failed";
      })
      .addCase(updateUsersDetail.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUsersDetail.fulfilled, (state, action) => {
        const index = state.users.findIndex(
          (item) => item.id === action.payload.data.id
        );
        state.users[index] = action.payload.data;
        state.status = "succeeded";
        state.userDetail = action.payload;
      })
      .addCase(updateUsersDetail.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateUserDetailFleet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUserDetailFleet.fulfilled, (state, action) => {
        // const index = state.users.findIndex(
        //   (item) => item.id === action.payload.data.id
        // );
        // state.users[index] = action.payload;
        // state.status = "succeeded";
        // state.userDetail = action.payload;
      })
      .addCase(updateUserDetailFleet.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(addUserDetailFleet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addUserDetailFleet.fulfilled, (state, action) => {
        // state.userDetail.User.unshift(action.payload.data);
      })
      .addCase(addUserDetailFleet.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { updateState, clearUserDetails } = usersSlice.actions;

export default usersSlice.reducer;

export const selectAllUsers = (state) => state.users.users;

export const selectUserDetails = (state) => state.users.userDetail;

import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import AppLogo from "../../../Assets/prestoLogo.png";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import LocalStorageService from "../../utils/LocalStorageService";
import Tabs from "./components/Tabs";

const PageHeader = (props) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    LocalStorageService.clearToken();
    navigate("/");
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const settings = ["Profile", "Account"];
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#22093F",
        height: "70px",
        boxShadow: "#6b5a7e 0px 25px 20px -20px",
        zIndex: 2,
      }}
    >
      <Toolbar>
        <Typography variant="h6">
          <img
            src={AppLogo}
            style={{
              maxWidth: "50%",
              height: "auto",
              marginRight: "10px",
              marginTop: "18px",
            }}
            alt="logo"
          />
        </Typography>
        <Tabs />

        <Box sx={{ marginLeft: "auto", paddingLeft: "1rem" }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountBoxIcon sx={{ color: "white", fontSize: "50px" }} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}

            {/* Add onClick handler for "Logout" */}
            <MenuItem onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;

import * as React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

export default function MultipleSelectChip({
  id,
  label,
  name,
  onSetItem,
  value,
  options,
  width,
}) {
  return (
    <div>
      <FormControl sx={{ m: 1, width: width }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          name={name}
          id={id}
          multiple
          value={value}
          onChange={(e) => {
            onSetItem(e.target.value);
          }}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((val, index) => (
                <Chip key={val + "-" + index} clickable label={val?.name} />
              ))}
            </Box>
          )}
        >
          {options.length ? (
            options.map((item) => (
              <MenuItem key={item.id} value={item}>
                <Checkbox checked={value.indexOf(item) > -1} />
                <ListItemText
                  primary={
                    item.name + " - " + item?.price + " - " + item?.energy_limit
                  }
                />
              </MenuItem>
            ))
          ) : (
            <Box>
              <MenuItem>{"No unassociated subscription plan found"}</MenuItem>
            </Box>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

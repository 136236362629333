import React, { useState } from "react";
import { Card, CardContent, Typography, Stack, Box } from "@mui/material";
import LoginButton from "../../lib/components/LoadingButton";
import { MuiOtpInput } from "mui-one-time-password-input";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { verifyOtp } from "../../api/login";
import LocalStorageService from "../../lib/utils/LocalStorageService";
import { sendOtp } from "../../api/login";

export const OtpLogin = ({ revertFunction, phoneNumber, countryCode }) => {
  const [otp, setOtp] = useState("");
  const [error, SetError] = useState("");
  const [loading, SetLoading] = useState(false);
  const [otpMessage, SetOtpMessage] = useState({});

  const navigate = useNavigate();

  const onClickHandler = () => {
    SetLoading(true);
    let formattedNumber = phoneNumber.replace(/[\s\-\(\)]/g, "");
    const countryCodeLength = countryCode.length;
    const phoneNumberLength = 10;
    if (
      formattedNumber.substring(
        formattedNumber.length - phoneNumberLength,
        formattedNumber.length
      ).length === 10 &&
      countryCodeLength > 1 &&
      otp.length === 4
    ) {
      const requestData = {
        country_code: formattedNumber.substring(
          0,
          formattedNumber.length - phoneNumberLength
        ),
        phone_number: formattedNumber.substring(
          formattedNumber.length - phoneNumberLength,
          formattedNumber.length
        ),
        otp: otp,
      };
      verifyOtp(
        requestData,
        (responseData) => {
          SetLoading(false);
          LocalStorageService.setToken(
            responseData?.data.AccessToken,
            responseData?.data.RefreshToken
          );
          navigate("/users");
        },
        (err) => {
          SetError(
            err?.response?.data?.status_message
              ? err?.response?.data?.status_message
              : "Opps something went wrong, please try again."
          );
          SetLoading(false);
        }
      );
    } else {
      SetError("Please Enter a Valid OTP.");
      SetLoading(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // If Enter key is pressed, trigger the button click event
      onClickHandler();
    }
  };

  const onClickAdminHandler = () => {
    let formattedNumber = phoneNumber.replace(/[\s\-\(\)]/g, "");
    const countryCodeLength = countryCode.length;
    const phoneNumberLength = 10;
    if (
      formattedNumber.substring(
        formattedNumber.length - phoneNumberLength,
        formattedNumber.length
      ).length === 10 &&
      countryCodeLength > 1
    ) {
      const requestData = {
        country_code: formattedNumber.substring(
          0,
          formattedNumber.length - phoneNumberLength
        ),
        phone_number: formattedNumber.substring(
          formattedNumber.length - phoneNumberLength,
          formattedNumber.length
        ),
      };
      sendOtp(
        requestData,
        (responseData) => {
          SetOtpMessage({
            ...otpMessage,
            otpSuccess: responseData.data.message,
          });
        },
        (err) => {
          SetOtpMessage({
            general: '"Opps something went wrong, please try again.',
          });
        }
      );
    } else {
      SetOtpMessage({ general: "Unable to login.Please try again." });
    }
  };
  return (
    <Card sx={{ width: "400px", height: "313px", boxShadow: "none" }}>
      <CardContent>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "28px",
            textAlign: "left",
            color: "#22093F",
          }}
        >
          <IconButton
            aria-label="go back"
            size="small"
            id="admin.otp.back.button"
            onClick={() => {
              revertFunction(false);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          Verify your OTP
        </Typography>
        <Typography
          sx={{
            color: "#6B5A7E",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            textAlign: "left",
          }}
        >
          Enter the 4-digit code we sent to <b>{phoneNumber}</b>.
        </Typography>
        <Stack direction={"column"} alignItems={"center"} spacing={1}>
          <MuiOtpInput
          autoFocus
            value={otp}
            onChange={setOtp}
            name={"phoneNumber"}
            label={"Phone Number"}
            type="text"
            onKeyDown={handleKeyPress}
          />
          {error && (
            <Typography
              sx={{
                color: "red",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "left",
              }}
            >
              {error}
            </Typography>
          )}

          <LoginButton
            id={"login.button"}
            width={130}
            sx={{ padding: "100px" }}
            onClick={() => {
              onClickHandler();
              SetOtpMessage({ otpSuccess: "" });
            }}
            loading={loading}
            onKeyDown={handleKeyPress}
          >
            Submit
          </LoginButton>
          <Box sx={{ justifyItems: "center", display: "grid" }}>
            <Typography
              sx={{
                color: "#6B5A7E",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "left",
              }}
            >
              Didn't receive a code?
            </Typography>
            <Typography
              sx={{
                color: "#6B5A7E",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "18px",
                textAlign: "left",
              }}
              onClick={() => {
                onClickAdminHandler();
                setOtp("");
                SetError("");
              }}
            >
              Check your number or{" "}
              <b style={{ color: "#7531C5", cursor: "pointer" }}>send again</b>
            </Typography>
            {otpMessage.otpSuccess && (
              <Typography
                sx={{
                  color: "	#008000",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "left",
                }}
              >
                {otpMessage.otpSuccess}
              </Typography>
            )}
            {otpMessage.general && (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  textAlign: "left",
                }}
              >
                {otpMessage.general}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default OtpLogin;

import React, { useEffect } from "react";
import { Wrapper } from "../../pages/User/UserStyles";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { length, propOr, equals } from "ramda";

import ButtonLink from "../../lib/components/ButtonLink";
import { format, parseISO } from "date-fns";

import {
  clearFleetDetails,
  selectUserFleets,
  fetchUserFleets,
} from "../../modules/fleets/fleetSlice";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import { PageContainer } from "./FleetsStyle";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";

export const UserFleet = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUserFleets(id));
  }, [id]);
  const userFleets = useSelector(selectUserFleets);
  const maskPhoneNumber = (row) => {
    let maskedData = propOr("-", "phone_number", row);
    if (maskedData) {
      maskedData = !equals("-", maskedData)
        ? `XXXXXX${maskedData.substring(
            length(maskedData) - 4,
            length(maskedData)
          )}`
        : maskedData;
      return !equals("-", maskedData)
        ? `${row.country_code} ${maskedData}`
        : "-";
    } else {
      return "-";
    }
  };

  const maskEmail = (row) => {
    let maskedData = propOr("-", "email", row);
    if (!maskedData || maskedData == "-") {
      return "-";
    }
    const [firstPart, domain] = maskedData?.split("@");
    if (!firstPart || !domain) {
      return "-";
    }
    const maskedFirstPart =
      firstPart.length > 2
        ? firstPart.substring(0, 2) + "*".repeat(firstPart.length - 2)
        : firstPart.charAt(0) + "*".repeat(firstPart.length - 1);

    const [domainPrefix, domainSuffix] = domain?.split(".");
    if (!domainPrefix || !domainSuffix) {
      return "-";
    }
    const maskedDomainPrefix =
      domainPrefix.length > 2
        ? "*".repeat(domainPrefix.length - 2) + domainPrefix.slice(-2)
        : "*".repeat(domainPrefix.length);

    return maskedFirstPart + "@" + maskedDomainPrefix + "." + domainSuffix;
  };
  const columns = [
    {
      label: "User id",
      id: "id",
      accessor: "user_id",
      cell: ({ row }) => {
        let Id = row?.original.user_id?.substring(0, 10)
          ? row.original.user_id?.substring(0, 10)
          : "-";
        return (
          <ButtonLink
            tooltipText={row?.original?.user_id}
            value={Id}
            id={row.original.user_id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/users/${row.original.user_id}`);
            }}
            href={`/users/${row.original.user_id}`}
          />
        );
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.user_id;
        const b = rowB.original.user_id;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Name",
      accessorFn: (row) => {
        return row.last_name
          ? `${row.first_name} ${row.last_name}`
          : `${row.first_name}`;
      },
    },
    {
      label: "Email address",
      accessor: "email",
      id: "email",
      // cell: ({ row }) => {
      //   return `${maskEmail(row.original)}`;
      // },
    },
    {
      label: "Ph. Number",
      id: "number",
      accessor: "phone_number",
      accessorFn: (row) => {
        return `${row.country_code} ${row.phone_number}`;
      },
      // cell: ({ row }) => {
      //   return `${maskPhoneNumber(row.original)}`;
      // },
    },
    {
      label: "Current plan",
      id: "current_plan",
      accessorFn: (row) => {
        let currentplan = propOr("-", "current_plan", row);
        return currentplan;
      },
    },

    {
      label: "Original attribution",
      id: "original attribution",
      accessorFn: (row) => {
        let originalAttribution = propOr("-", "original_attribution", row);
        return originalAttribution;
      },
    },
    {
      label: "Last charged on",
      id: "last_charging_session_date",
      accessorFn: (row) => {
        let date_of_last_charging = propOr(
          "-",
          "last_charging_session_date",
          row
        );
        date_of_last_charging = date_of_last_charging
          .replace(" ", "T")
          .replace(/(\s\+\d{4}\s\w{3})$/, "Z");

        return date_of_last_charging
          ? format(parseISO(date_of_last_charging), "dd/MM/yyyy")
          : "-";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.last_charging_session_date;
        const b = rowB.original.last_charging_session_date;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Enroll date",
      id: "user_created_at",
      accessorFn: (row) => {
        let created_at = propOr("-", "user_created_at", row);
        created_at = created_at
          .replace(" ", "T")
          .replace(/(\s\+\d{4}\s\w{3})$/, "Z");
        return created_at ? format(parseISO(created_at), "dd/MM/yyyy") : "-";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.user_created_at;
        const b = rowB.original.user_created_at;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
  ];
  const reactTableColumns = toReactTableColumns(columns);
  return (
    <Wrapper sx={{ background: "white" }}>
      <PageContainer sx={{ background: "white", marginTop: "-20px" }}>
        <GenericTable data={userFleets} columns={reactTableColumns} />
      </PageContainer>
    </Wrapper>
  );
};

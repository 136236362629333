import React, { useEffect } from "react";
import { Wrapper } from "../../pages/User/UserStyles";
import { Grid, Typography, Stack, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardView from "../../lib/components/CardView";
import { propOr } from "ramda";
import {
  fetchSubscriptionPlanDetails,
  selectSubscriptionPlanDetails,
  clearSubscriptionDetails,
} from "../../modules/subscriptionPlan/subscriptionPlanSlice";
import Skeleton from "@mui/material/Skeleton";

export const SubscriptionPlanDetails = () => {
  const dispatch = useDispatch();
  const planDetails = useSelector(selectSubscriptionPlanDetails);
  const singleSubscriptionLoading = useSelector(
    (state) => state.subscriptionPlans.singleSubscriptionLoading
  );
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchSubscriptionPlanDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(clearSubscriptionDetails());
  }, []);

  let active = propOr("-", "active", planDetails);
  let display = propOr("-", "display", planDetails);
  // let planPriceValue = propOr("-", "price", planDetails);

  let planPriceValue =
    (planDetails && planDetails?.price == undefined) ||
    planDetails?.price == null ||
    planDetails?.price == 0 ||
    planDetails?.price == " "
      ? "-"
      : `$ ${propOr("-", "price", planDetails)}`;

  function formatTimeRange(startTimeISO, stopTimeISO) {
    const startTime = new Date(startTimeISO);
    const stopTime = new Date(stopTimeISO);
    const formattedStartTime = startTime.toLocaleTimeString([], {
      hour: "numeric",
      hour12: true, 
    });

    const formattedStopTime = stopTime
      .toLocaleTimeString([], {
        hour: "numeric",
        hour12: true, 
      })
      .replace(/:\d{2}\s+/i, ""); 

    return `${formattedStartTime} to ${formattedStopTime}`;
  }

  const blackOutStartTime = planDetails?.black_out_start_time;
  const blackOutStopTime = planDetails?.black_out_stop_time;

  const formattedTimeRange = formatTimeRange(
    blackOutStartTime,
    blackOutStopTime
  );

  return (
    <Wrapper sx={{ marginTop: "60px" }}>
      <Grid container spacing={2}>
        {singleSubscriptionLoading === "loading" ? (
          <Box>
            <Grid item xs={4}>
              <CardView
                padding={"20px"}
                height={"70vh"}
                width={"30vw"}
                isboxShadow={true}
                title={<Skeleton height={130} width={550} />}
                content={
                  <Box sx={{ marginTop: "-25%", marginLeft: "15px" }}>
                    <Skeleton height={600} width={550} />
                  </Box>
                }
              />
            </Grid>
          </Box>
        ) : (
          <Grid item xs={4}>
            <CardView
              padding={"20px"}
              height={"70vh"}
              width={"30vw"}
              isboxShadow={true}
              title={
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  {planDetails?.name}
                </Typography>
              }
              content={
                <>
                  {" "}
                  <Grid sx={{ padding: "12px" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid grey",
                      }}
                    ></Typography>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px", marginTop: "8px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Plan value:
                          <Typography>{planPriceValue}</Typography>
                        </Typography>
                      </Box>

                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          City:
                          <Typography>{planDetails?.city}</Typography>
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px", marginTop: "8px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Validity:
                          <Typography>
                            {planDetails?.valid_hours
                              ? `${planDetails?.valid_hours / 24} days`
                              : "-"}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Energy limit:
                          <Typography>
                            {planDetails?.energy_limit
                              ? `${planDetails?.energy_limit} kWh`
                              : "0 kWh"}
                          </Typography>
                          
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px", marginTop: "8px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Is active:
                          <Typography>
                            {active === true ? "Yes" : "No"}
                          </Typography>
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Is display:
                          <Typography>
                            {display === true ? "Yes" : "No"}{" "}
                          </Typography>
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px", marginTop: "8px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Blackout time:
                          <Typography>{formattedTimeRange}</Typography>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Blackout price:
                          <Typography>
                            {planDetails?.black_out_pricing != null
                              ? `$ ${planDetails?.black_out_pricing}`
                              : "-"}
                          </Typography>
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </>
              }
            ></CardView>
          </Grid>
        )}
      </Grid>
    </Wrapper>
  );
};

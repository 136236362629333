import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUser } from "../../modules/auth/authSlice";
import { Typography } from "@mui/material";

export const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <>
      <Typography
        variant="h1"
        sx={{
          textAlign: "center",
          marginTop: "15rem",
          marginLeft: "10rem",
          fontWeight: "10%",
          color: "#1b1b1b",
        }}
      >
        Coming Soon
      </Typography>

    </>
  );
};

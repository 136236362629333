import MuiLoadingButton from '@mui/lab/LoadingButton'
import {styled } from '@mui/material/styles'


const LoadingButton = styled(({
    width,
    id,
    styles,
    ...props
}) => (
    <MuiLoadingButton id={id} sx={{width, ...styles}} {...props} />
))(
    `
    && {
        padding: 8px, 24px, 8px, 24px;
        border-radius: 10px;
        gap: 8px;
        text-transform: none;
        background-color: #22093F;
        color: white;
        height: 48px;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        white-space: nowrap;
        .MuiCircularProgress-svg {
            color: white;
        }
    },
    `
);

export default LoadingButton;

import { Card, Typography, Grid } from "@mui/material";
import { isNil } from "ramda";

const CardView = ({
  id,
  title,
  content,
  height,
  width,
  minHeight,
  padding,
  overflow,
  border,
  isboxShadow,
}) => {
  return (
    <Card
      variant="outlined"
      square
      sx={{
        height,
        width,
        padding: 2,
        borderStyle: "solid",
        borderRadius: "5px",
        overflow: "hidden",
        boxShadow: isboxShadow
          ? "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px"
          : "none",
      }}
    >
      <Grid container direction={"column"}>
        {!isNil(title) && (
          <Grid item xs={1}>
            <Typography sx={{ p: 2, color: "#22093F" }} id={`cardTitle.${id}`}>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid item lg={12} xs={6}>
          {content}
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardView;

import React, { useEffect, useState } from "react";
import { Wrapper } from "../../pages/User/UserStyles";
import { Grid, Typography, Stack, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardView from "../../lib/components/CardView";
import { length } from "ramda";
import {
  fetchChargingStationsDetails,
  selectChargingStationDetail,
  clearStationDetails,
} from "../../modules/chargingStation/chargingStationSlice";
import { StationEvseLog } from "./EvseLog";
import AccordionTable from "../../lib/components/AccordianTable/Index";
import Skeleton from "@mui/material/Skeleton";
import EditIcon from "@mui/icons-material/Edit";
import EditStationDetails from "./EditStationDetails";
import { propOr } from "ramda";

export const ChargingStaionDetails = () => {
  const dispatch = useDispatch();
  const stationDetails = useSelector(selectChargingStationDetail);
  const singleStationLoading = useSelector(
    (state) => state.chargingStations.singleStationLoading
  );
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchChargingStationsDetails(id));
  }, [openModal]);
  useEffect(() => {
    dispatch(clearStationDetails());
  }, []);

  let TotalEVSE = length(stationDetails?.evse);
  let availableEVSE =
    stationDetails?.evse &&
    stationDetails?.evse.filter((item) => item.status === "AVAILABLE").length;
  return (
    <Wrapper sx={{ marginTop: "60px" }}>
      <Grid container spacing={2}>
        {singleStationLoading === "loading" && stationDetails.length === 0 ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CardView
                  padding={"20px"}
                  height={"70vh"}
                  width={"30vw"}
                  isboxShadow={true}
                  title={<Skeleton height={130} width={550} />}
                  content={
                    <Box sx={{ marginTop: "-25%", marginLeft: "15px" }}>
                      <Skeleton height={600} width={550} />
                    </Box>
                  }
                />
              </Grid>

              <Grid item xs={8}>
                <Box sx={{ marginLeft: "31%" }}>
                  <Skeleton height={130} width={750} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Grid item xs={4}>
              <CardView
                padding={"20px"}
                width={"30vw"}
                isboxShadow={true}
                title={
                  <>
                    <Stack>
                      <Box>
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {stationDetails?.station_name
                            ? stationDetails?.station_name
                            : "-"}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <EditIcon
                        sx={{ fontSize: 30, cursor: "pointer" }}
                        onClick={() => setOpenModal(true)}
                      />
                    </Box>
                  </>
                }
                content={
                  <>
                    {" "}
                    <Grid sx={{ padding: "12px" }}>
                      <Typography
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid grey",
                        }}
                      ></Typography>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px", marginTop: "8px" }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            CPO network :
                            <Typography>
                              {stationDetails?.cpo?.name
                                ? stationDetails?.cpo?.name
                                : "-"}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box>
                          {/* <Typography
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            State:
                            <Typography>
                              {stationDetails?.state
                                ? stationDetails?.state
                                : "-"}
                            </Typography>
                          </Typography> */}
                          <Typography sx={{ fontWeight: "bold" }}>
                            Street address:{" "}
                            <Typography>
                              {stationDetails?.street_address
                                ? stationDetails?.street_address
                                : "-"}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px" }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            City:
                            <Typography>
                              {stationDetails?.city ? stationDetails?.city : ""}
                            </Typography>
                          </Typography>
                        </Box>

                        <Box>
                          <Typography>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              State:
                              <Typography>
                                {stationDetails?.state
                                  ? stationDetails?.state
                                  : "-"}
                              </Typography>
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px" }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            Postal code:
                            <Typography>
                              {stationDetails?.postal_code
                                ? stationDetails?.postal_code
                                : "-"}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Total number of EVSE (Available / Total):
                            <Typography>
                              {`${availableEVSE}/${TotalEVSE}`}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px" }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            24Hours:
                          </Typography>
                          <Typography>
                            {stationDetails.opening_times?.twenty_four_seven
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Energy fee:
                            <Typography>
                              {"$ "}{propOr(
                                "-",
                                "energy_fee",
                                stationDetails?.pricing
                              )}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px" }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Session fee:
                            <Typography>
                            {"$ "} {propOr(
                                "-",
                                "session_fee",
                                stationDetails?.pricing
                              )}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Idle fee:
                            <Typography>
                            {"$ "} {propOr("-", "idle_fee", stationDetails?.pricing)}
                            </Typography>
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px" }}
                      >
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Parking fee:
                            <Typography>
                            {"$ "} {propOr(
                                "-",
                                "parking_fee",
                                stationDetails?.pricing
                              )}
                            </Typography>
                          </Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            ToU price:
                            <Typography>{"-"}</Typography>
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-start"}
                        sx={{ paddingBottom: "15px" }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              paddingBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            Direction:
                            <Typography>
                              {stationDetails?.direction
                                ? stationDetails?.direction
                                : "-"}
                            </Typography>
                          </Typography>
                        </Box>
                        
                      </Stack>
                    </Grid>
                  </>
                }
              ></CardView>
            </Grid>
            <Grid item xs={8}>
              <AccordionTable
                summary={<Typography variant="h6"> EVSEs</Typography>}
                details={
                  <>
                    <Grid sx={{ width: "90%", background: "white" }}>
                      <StationEvseLog />
                    </Grid>
                  </>
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      {openModal && (
        <>
          <EditStationDetails open={openModal} closeModal={setOpenModal} />
        </>
      )}
    </Wrapper>
  );
};

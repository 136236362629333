import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DialogButton from "../LoadingButton";
import Link from "@mui/material/Link";

const EditDetailsFormDialog = ({
  title,
  open,
  setOpen,
  content,
  width,
  height,
  onSubmit,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      sx={{
        borderRadius: "15px",
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "24px",
            color: "#22093F",
          }}
        >
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ width: width, height: height }}>
        {content}
      </DialogContent>
      <Divider
        sx={{
          marginBottom: "10px",
        }}
      />
      <DialogActions sx={{ paddingBottom: "8px" }}>
        <Link
          component="button"
          variant="body2"
          underline="none"
          onClick={() => {
            handleClose();
          }}
        >
          <Typography sx={{ fontSize: "18px" }}>Cancel</Typography>
        </Link>
        <DialogButton
          onClick={() => {
            onSubmit();
          }}
          id={"submit.button"}
          width={"120px"}
          type="submit"
        >
          Submit
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};
export default EditDetailsFormDialog;

import styled from "@emotion/styled";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  gap: 20%;
  align-items: center;
  text-align: center;
  background: #22093f; /* fallback for old browsers */
  font-weight: bold;
  color: #22093f;
  font-size: 35px;
  padding: 20px;

  .register {
    width: 35vw;
    float: right;
    border-radius: 20px;
    background-color: white;
    height: 30vw;
    border-radius: 20px;
    margin-left: 25%;
  }

  .leftdiv {
    width: 45%;
    // background-color: grey;
    font-family: arial;
    margin-left: -5%;
    height: 80%;
    padding: 5px 10px 10px 10px;
    border-radius: 10px;
    display: inline-block;
  }

  & .MuiBox-root {
    border-radius: 30px;
  }

  .superAdminText {
    font-family: Work Sans;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #4e3a65;
  }
  .superAdminsubtext {
    font-family: Work Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    color: #4e3a65;
  }

  .rightdiv {
    width: 45%;
    // background-color: grey;
    font-family: arial;
    margin-left: 5%;
    height: 80%;
    padding: 5px 10px 10px 10px;
    border-radius: 10px;
    display: inline-block;
  }

  .loginErrorMessage {
    color: red;
    margin: 0;
  }

  @media screen and (min-width: 860px) {
    .register .col-2 img {
      display: initial;
    }
  }
`;

import React, { useEffect, useState } from "react";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import {
  fetchFleetDetails,
  fetchFleets,
  selectAllFleets,
} from "../../modules/fleets/fleetSlice";

import { Wrapper, PageContainer } from "./FleetsStyle";

import { useNavigate } from "react-router-dom";

import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import { renderAsCustom } from "../../lib/components/Table/Cell/CellRenderers";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import ButtonLink from "../../lib/components/ButtonLink";
import { Typography, Box } from "@mui/material";
import AddFleet from "./AddFleet";
import SubmitButton from "../../lib/components/LoadingButton/index";
export const Fleets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fleets = useSelector(selectAllFleets);
  const fleetLoadingStatus = useSelector(
    (state) => state.fleets.fleetLoadingStatus
  );

  const [openCreateFleetModal, setOpenCreateFleetModal] = useState(false);

  useEffect(() => {
    dispatch(fetchFleets());
    // eslint-disable-next-line
  }, [openCreateFleetModal]);
  const columns = [
    {
      label: "Name",
      accessor: "name",
      renderType: renderAsCustom(({ row }) => {
        const fleetName = row.original.name ? row.original.name : "-";
        return (
          <ButtonLink
            tooltipText={fleetName}
            value={fleetName}
            id={row.id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/fleets/${row.original.id}`);
            }}
            href={`/fleets/${row.original.id}`}
          />
        );
      }),
    },
    {
      label: "Segment",
      accessorFn: (row) => {
        if (row && row.fleet_segment) {
          return row.fleet_segment;
        } else {
          return "-";
        }
      },
    },
  ];
  const reactTableColumns = toReactTableColumns(columns);
  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          color: "#22093F",
          fontWeight: "bold",
          paddingTop: "30px",
        }}
      >
        Fleets
      </Typography>
      <Box sx={{ paddingTop: "8px" }}>
        <SubmitButton
          id={"submit.button"}
          width={"120px"}
          type="button"
          onClick={() => setOpenCreateFleetModal(true)}
        >
          Create fleet
        </SubmitButton>
      </Box>
      {fleetLoadingStatus === "loading" ? (
        <Box sx={{ marginTop: "-8%" }}>
          <Skeleton animation="wave" width={"90%"} height={900} />
        </Box>
      ) : (
        <>
          <PageContainer>
            <GenericTable data={fleets} columns={reactTableColumns} />
          </PageContainer>
        </>
      )}
      {openCreateFleetModal && (
        <>
          <AddFleet
            open={openCreateFleetModal}
            closeModal={setOpenCreateFleetModal}
          />
        </>
      )}
    </Wrapper>
  );
};

// import {React,useState,useEffect}from "react";
import React, { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  DateRangeColumnFilter,
  dateBetweenFilterFn,
} from "../../../pages/ChargingSessions/filter";
// import { TableDiv, StyledCard,StyledTable} from "./UserTableStyle";
import { TableDiv } from "./GenericTableStyle";
import { Stack } from "@mui/material";
import styled from "styled-components";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { length } from "ramda";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

// Styled Table
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 11px 4px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: large;
  }

  th {
    font-weight: bold;
    background-color: #4e3a65;
    font-size: 16px; /* Increase font size for thead */
    color: #fff; /* Text color for thead */
  }

  tr:hover {
    /* background-color: #f9f9f9; */
    background-color: grey;
  }
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
`;

// Styled Buttons
export const StyledButton = styled.button`
  width: 10%;
  font-weight: bold;
  font-size: 18px;
  border-radius: 5px;
  color: #22093f;
  background-color: #f2f2f2;
  border: none;
  padding: 8px 12px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;
export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export const StyledSpinner = styled.div`
  /* Customize the spinner styles here */
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const GenericTable = ({ data, columns, page }) => {
  const [filtering, setFiltering] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFiltering, setcolumnFiltering] = useState([]);
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      datefilter: dateBetweenFilterFn,
    },
    // useFilters,

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnFilters: columnFiltering,
      globalFilter: filtering,
      sorting: sorting,
    },
    onGlobalFilterChange: setFiltering,
    onSortingChange: setSorting,
    onColumnFiltersChange: setcolumnFiltering,
    manualPagination: page ? true : false,
    // defaultColumn: { Filter: DefaultColumnFilter },
  });

  // return <></>
  return (
    <TableDiv>
      <Stack direction={"row-reverse"} sx={{ marginBottom: "8px" }}>
        <input
          type="text"
          value={filtering}
          onChange={(e) => setFiltering(e.target.value)}
          style={{
            width: "20%",
            height: "3vh",
            borderRadius: "8px",
            borderColor: "#4e3a65",
            padding: "10px",
            fontSize: "16px",
          }}
          placeholder=" Smart Search"
        />

        {table
          .getHeaderGroups()
          ?.map((headerGroup) =>
            headerGroup.headers?.map((header) =>
              header.column.columnDef.header === "Time Stamp" ? (
                <div>
                  {header.column.getCanFilter() ? (
                    <DateRangeColumnFilter column={header.column} />
                  ) : null}
                </div>
              ) : null
            )
          )}
      </Stack>

      <StyledTable>
        <TableHead>
          {table.getHeaderGroups()?.map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers?.map((header) => (
                <TableCell
                  key={header.id}
                  // onClick={header.column.getToggleSortingHandler()}
                >
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                  <span
                    style={{ marginLeft: "auto" }}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.column.getIsSorted() &&
                    header.column.columnDef.header !== "Session Rating" ? (
                      {
                        asc: (
                          <NorthIcon sx={{ height: 15, cursor: "pointer" }} />
                        ),
                        desc: (
                          <SouthIcon sx={{ height: 15, cursor: "pointer" }} />
                        ),
                      }[header.column.getIsSorted()]
                    ) : header.column.columnDef.header !== "Session Rating" ? (
                      <SyncAltIcon
                        sx={{
                          height: 15,
                          rotate: "90deg",
                          cursor: "pointer",
                        }}
                      />
                    ) : null}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody>
          {length(table.getRowModel().rows) <= 0 && (
            <TableRow key={"0.noData"}>
              <TableCell colSpan={3}>No Data available</TableCell>
            </TableRow>
          )}
          {table.getRowModel().rows?.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells()?.map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      {!page && (
        <ButtonContainer>
          <StyledButton onClick={() => table.setPageIndex(0)}>
            {"<<"}
          </StyledButton>
          <StyledButton
            disabled={!table.getCanPreviousPage()}
            onClick={() => {
              table.previousPage();
            }}
          >
            {"<"}
          </StyledButton>
          <StyledButton
            disabled={!table.getCanNextPage()}
            onClick={() => {
              table.nextPage();
            }}
          >
            {">"}
          </StyledButton>
          <StyledButton
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
            }}
          >
            {">>"}
          </StyledButton>
        </ButtonContainer>
      )}
    </TableDiv>
  );
};

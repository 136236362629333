import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice"
import chargingStationSlice from "./chargingStation/chargingStationSlice";
import chargingSessionSlice from "./chargingSession/chargingSessionSlice";
import cpoSlice from './cpo/cpoSlice'
import fetchSubscriptionPlansSlice from './subscriptionPlan/subscriptionPlanSlice'
import fleetSlice from "./fleets/fleetSlice";

const Modules = configureStore({

  reducer: {
    auth: authReducer,
    users: userReducer,
    chargingStations: chargingStationSlice,
    chargingSessions: chargingSessionSlice,
    cpos: cpoSlice,
    fleets:fleetSlice,
    subscriptionPlans: fetchSubscriptionPlansSlice,
  },

});
export default Modules;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFleets,
  getFleetsDetails,
  getUserFleetsDetails,
  createFleet,
} from "../../api/fleets";

const initialState = {
  status: "idle",
  fleets: [],
  fleetLoadingStatus: "",
  FleetDetails: {},
  singleFleetsLoadingStatus: "",
  userFleets: "",
};

export const fetchFleets = createAsyncThunk("/fetchFleets", async () => {
  const response = await getFleets();
  return response?.data;
});

export const fetchFleetDetails = createAsyncThunk(
  "/fetchFleetDetails",
  async (id) => {
    const response = await getFleetsDetails(id);
    return response?.data;
  }
);
export const fetchUserFleets = createAsyncThunk(
  "/fetchUserFleetDetails",
  async (id) => {
    const response = await getUserFleetsDetails(id);
    return response?.data;
  }
);

export const addFleet = createAsyncThunk(
  "/createFleet",
  async ({ data, successCB, errorCB }) => {
    const response = await createFleet(data, successCB, errorCB);
    return response?.data;
  }
);

const fleetSlice = createSlice({
  name: "fleets",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
    },
    clearFleetDetails: (state) => {
      state.FleetDetails = "";
    },
    // fetchFleetDetails:(state,action)=>{
    //     state.FleetDetails = action.payload;
    // }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFleets.pending, (state, action) => {
        state.status = "loading";
        state.fleetLoadingStatus = "loading";
      })
      .addCase(fetchFleets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fleets = action.payload?.data;
        state.fleetLoadingStatus = "succeeded";
      })

      .addCase(fetchFleets.rejected, (state, action) => {
        state.status = "failed";
        state.fleetLoadingStatus = "failed";
      })
      .addCase(fetchFleetDetails.pending, (state, action) => {
        state.status = "loading";

        state.singleFleetsLoadingStatus = "loading";
      })
      .addCase(fetchFleetDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.FleetDetails = action.payload?.data;
        state.singleFleetsLoadingStatus = "succeeded";
      })
      .addCase(fetchFleetDetails.rejected, (state, action) => {
        state.status = "failed";
        state.FleetDetails = action.payload?.data;
        state.singleFleetsLoadingStatus = "failed";
      })
      .addCase(fetchUserFleets.pending, (state, action) => {
        state.status = "loading";
        state.singleFleetsLoadingStatus = "loading";
      })
      .addCase(fetchUserFleets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userFleets = action.payload?.data;

        state.singleFleetsLoadingStatus = "succeeded";
      })
      .addCase(fetchUserFleets.rejected, (state, action) => {
        state.status = "failed";
        state.singleFleetsLoadingStatus = "failed";
      })
      .addCase(addFleet.pending, (state, action) => {
        state.status = "loading";
        state.fleetLoadingStatus = "loading";
      })
      .addCase(addFleet.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.fleetLoadingStatus = "succeeded";
        // state.fleets.unshift(action.payload?.data);
      })

      .addCase(addFleet.rejected, (state, action) => {
        state.status = "failed";
        state.fleetLoadingStatus = "failed";
      });
  },
});

export const { updateState, clearFleetDetails } = fleetSlice.actions;
export default fleetSlice.reducer;

export const selectAllFleets = (state) => state.fleets.fleets;

export const selectFleetDetails = (state) => {
  return state.fleets.FleetDetails;
};

export const selectUserFleets = (state) => {
  return state.fleets.userFleets;
};

import prestoLogo from "../../Assets/prestoLogo.png";
import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import { Wrapper } from "./Login.Styles";
import { Typography, Stack } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import ButtonLink from "../../lib/components/ButtonLink";
import { useNavigate } from "react-router-dom";
import LoginButton from "../../lib/components/LoadingButton";
import PhoneNumberInputView from "../../lib/components/PhoneNumberInputView";
import OtpLogin from "./OtpLogin";
import { sendOtp, superAdminLogin } from "../../api/login";
import LocalStorageService from "../../lib/utils/LocalStorageService";

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, SetPhoneNumber] = useState("");
  const [countryCode, SetCountryCode] = useState("");
  const [showOtpLogin, setShowOtpLogin] = useState(false);
  const [error, SetError] = useState({});
  const [loading, SetLoading] = useState(false);

  const onChangePhoneValue = (phoneData, countryData) => {
    SetPhoneNumber(phoneData);
    SetCountryCode(`+${countryData?.dialCode}`);
  };

  const onClickAdminHandler = () => {
    SetLoading(true);
    let formattedNumber = phoneNumber.replace(/[\s\-\(\)]/g, "");
    const countryCodeLength = countryCode.length;
    const phoneNumberLength = 10;
    if (
      formattedNumber.substring(
        formattedNumber.length - phoneNumberLength,
        formattedNumber.length
      ).length === 10 &&
      countryCodeLength > 1
    ) {
      const requestData = {
        country_code: formattedNumber.substring(
          0,
          formattedNumber.length - phoneNumberLength
        ),
        phone_number: formattedNumber.substring(
          formattedNumber.length - phoneNumberLength,
          formattedNumber.length
        ),
      };
      sendOtp(
        requestData,
        (responseData) => {
          SetError({ ...error, phoneNumber: "" });
          SetLoading(false);
          setShowOtpLogin(true);
        },
        (err) => {
          SetError({
            ...error,
            phoneNumber: err?.response?.data?.status_message
              ? err?.response?.data?.status_message
              : "Opps something went wrong, please try again.",
          });
          SetLoading(false);
        }
      );
    } else {
      SetError({ ...error, phoneNumber: "Phone Number format is invalid." });
      SetLoading(false);
    }
  };

  const onClickSuperAdminHandler = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const requestData = {
        access_token: tokenResponse.access_token,
      };
      superAdminLogin(
        requestData,
        (res) => {
          if (res.status) {
            SetError({ ...error, general: "" });
            const { access_token, refresh_token } = res.data;
            LocalStorageService.setToken(access_token, refresh_token);
            navigate("/users");
          }
        },
        (error) => {
          SetError({ ...error, general: "Unable to login" });
        }
      );
    },
    onError: () => {
      SetError({ ...error, general: "Login failed" });
    },
  });
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // If Enter key is pressed, trigger the button click event
      onClickAdminHandler();
    }
  };

  return (
    <Wrapper>
      <Box>
        <img src={prestoLogo} style={{ padding: "65%" }} alt="" />
      </Box>

      <Paper
        className="Container "
        variant={"outlined"}
        sx={{
          padding: "30px",
          width: "500px",
          borderRadius: "40px",
          height: "390px",
          boxShadow: `
          0.3px 0.3px 0.3px hsl(268deg 100% 3% / 0.75),
          0.4px 0.4px 0.4px -0.6px hsl(268deg 100% 3% / 0.68),
          0.9px 0.8px 0.9px -1.1px hsl(268deg 100% 3% / 0.6),
          2.1px 2px 2.2px -1.7px hsl(268deg 100% 3% / 0.53),
          4.6px 4.4px 4.8px -2.2px hsl(268deg 100% 3% / 0.45),
          8.6px 8.2px 8.9px -2.8px hsl(268deg 100% 3% / 0.38),
          14.7px 13.9px 15.2px -3.3px hsl(268deg 100% 3% / 0.3),
          23.1px 21.9px 23.9px -3.9px hsl(268deg 100% 3% / 0.23),
          34.3px 32.5px 35.4px -4.4px hsl(268deg 100% 3% / 0.15),
          48.6px 46.2px 50.3px -5px hsl(268deg 100% 3% / 0.08);
          `,
        }}
      >
        <Typography
          sx={{ color: "#22093F", fontSize: "32px", fontWeight: "600" }}
        >
          SIGN IN
        </Typography>
        {showOtpLogin ? (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            paddingTop={"20px"}
            alignItems={"center"}
          >
            <OtpLogin
              revertFunction={setShowOtpLogin}
              phoneNumber={phoneNumber}
              countryCode={countryCode}
            />
          </Stack>
        ) : (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            paddingTop={"20px"}
            alignItems={"center"}
          >
            <PhoneNumberInputView
            autoFocus
              label={"Mobile Number"}
              id={"login.admin"}
              value={phoneNumber}
              setValue={onChangePhoneValue}
              required={true}
              helperText={error?.phoneNumber ? error.phoneNumber : ""}
              isError={error?.phoneNumber ? true : false}
              styles={{ width: 300, paddingBottom: "30px" }}
              onKeyPress={handleKeyPress}
            />
            <LoginButton
              id={"admin.login.button"}
              width={"156px"}
              onClick={onClickAdminHandler}
              loading={loading}
              
              
              
            >
              Login as Admin
            </LoginButton>
            <Box sx={{ paddingTop: "12px" }}>
              <Typography
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"18px"}
                color={"#4E3A65"}
              >
                Are you a Super-Admin?
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={"12px"}
                lineHeight={"18px"}
                color={"#4E3A65"}
              >
                Click here to login as{" "}
                <div style={{cursor:"pointer"}}>


                <ButtonLink
                  id={"superadmin.login"}
                  value={"Super Admin"}
                  onClick={() => {
                    onClickSuperAdminHandler();
                    SetError({ ...error, general: "" });
                  }}
                />
                </div>
                .
              </Typography>
              <Typography
                sx={{
                  color: "red",
                  fontFamily: "Roboto",
                }}
              >
                {error?.general ? error?.general : ""}
              </Typography>
            </Box>
          </Stack>
        )}
      </Paper>
    </Wrapper>
  );
};

export default Login;

import axios from "./axios";

export const getChargingStations = async () => {
  const response = await axios.get("/1/admin/stations");
  return response;
};

export const getChargingStationsDetails = async (id) => {
  const response = await axios.get(`/1/admin/stations/${id}`);
  return response;
};

export const updateStationDetails = async (id, data, successCB, errorCB) =>{
  return await axios.patch(`/1/admin/stations/${id}`,data).then(
    (res) =>{
      successCB(res.data);
      return res
    },
    (error) =>{
      errorCB(error);
      throw new Error(error.response)
      // return error;
    }
  );
};

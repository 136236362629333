import React, { useEffect } from "react";
import { Wrapper } from "../../pages/User/UserStyles";
import { Grid, Typography, Stack, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardView from "../../lib/components/CardView";
import { length } from "ramda";
import { fetchCPODetails, selectCpoDetails,clearStationDetails } from "../../modules/cpo/cpoSlice";
import Skeleton from "@mui/material/Skeleton";

export const CpoDetails = () => {
  const dispatch = useDispatch();
  const cpoDetails = useSelector(selectCpoDetails);
  const singleCpoLoadingStatus = useSelector((state) => state.cpos.singleCpoLoadingStatus)
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchCPODetails(id));
  }, [dispatch, id]);
  useEffect(() =>{
    dispatch(clearStationDetails())
  },[])
  return (
    <Wrapper sx={{ marginTop: "60px" }}>
      <Grid container spacing={2}>

        {singleCpoLoadingStatus === 'Loading' ?
        
        <>
        <Grid item xs={4}>
          <CardView
            padding={"20px"}
            height={"70vh"}
            width={"30vw"}
            isboxShadow={true}
            title={<Skeleton height={130} width={550}/>}
            content={
            <>
            <Box sx={{marginTop:"-25%",marginLeft:'15px'}}>
            <Skeleton height={600} width={550} />
            </Box>
            
            </>
            }
          />
        </Grid>

        
      </>

: 
      
      
      
      
      <>
        <Grid item xs={4}>
          <CardView
            padding={"20px"}
            height={"70vh"}
            width={"30vw"}
            isboxShadow={true}
            title={
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                {cpoDetails?.name}
              </Typography>
            }
            content={
              <>
                {" "}
                <Grid sx={{ padding: "12px" }}>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid grey",
                    }}
                  ></Typography>

                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    sx={{ paddingBottom: "15px", marginTop: "8px" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Cpo Id:
                        <Typography>{cpoDetails?.id}</Typography>
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    sx={{ paddingBottom: "15px", marginTop: "8px" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Total number of stations:
                        <Typography>
                          {length(cpoDetails?.charging_stations)}
                        </Typography>
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              </>
            }
          ></CardView>
        </Grid>
        </>
        }
      </Grid>
    </Wrapper>
  );
};

//charging session log that will display in the userdetails page
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import {
  PageContainer,
  Wrapper,
} from "../ChargingSessions/chargingSessionStyles";

import { format, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchUsersDetail,
  selectUserDetails,
} from "../../modules/user/userSlice";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import ButtonLink from "../../lib/components/ButtonLink";
import { prop, propOr } from "ramda";
import {
  StyledButton,
  ButtonContainer,
} from "../../lib/components/Table/GenericTable";
export const PlanPurchase = () => {
  const dispatch = useDispatch();
  const details = useSelector(selectUserDetails);
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchUsersDetail(id));
  }, [dispatch, id]);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 5;
  const navigate = useNavigate();
  const subscription = details?.subscriptions ? details?.subscriptions : [];
  const columns = [
    {
      label: "Plan name",
      id: "name",
      accessorFn: (row) => {
        return row.SubscriptionPlan.name;
      },
      cell: ({ row }) => {
        const planName = row.original.SubscriptionPlan.name;
        return (
          <ButtonLink
            tooltipText={planName}
            value={planName}
            id={row.original.SubscriptionPlan.id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/plans/${row.original.SubscriptionPlan.id}`);
            }}
            href={`/plans/${row.original.SubscriptionPlan.id}`}
          />
        );
      },
      sortingFn: (rowA, rowB) => {
        const a = prop("name", rowA.original.SubscriptionPlan);
        const b = prop("name", rowB.original.SubscriptionPlan);
        if (a === undefined && b === undefined) {
          return 0;
        } else if (a === undefined) {
          return 1;
        } else if (b === undefined) {
          return -1;
        }

        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Plan value",
      id: "price",
      accessorFn: (row) => {
        let planValue = propOr("-", "price", row.SubscriptionPlan);
        if (planValue == undefined || planValue == null || planValue == 0) {
          return "-";
        } else {
          return `$ ${planValue}`;
        }
      },
    },

    {
      label: "Valid through",
      id: "renewal_date_time",
      accessorFn: (row) => {
        if (row?.renewal_date_time) {
          return format(parseISO(row.renewal_date_time), "dd/MM/yyyy");
        }
        return "-";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.renewal_date_time;
        const b = rowB.original.renewal_date_time;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Balance(kWh)",
      id: "remaining_energy",
      accessorFn: (row) => {
        if (row?.remaining_energy || row?.remaining_energy === 0) {
          return row?.remaining_energy.toFixed(2);
        }
        return "-";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.remaining_energy;
        const b = rowB.original.remaining_energy;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
  ];
  const reactTableColumns = toReactTableColumns(columns);

  return (
    <Wrapper sx={{ background: "white" }}>
      <PageContainer sx={{ background: "white", marginTop: "-20px" }}>
        <GenericTable
          data={subscription.slice(
            pageIndex * pageSize,
            (pageIndex + 1) * pageSize
          )}
          columns={reactTableColumns}
          page={true}
        />
        <ButtonContainer>
          <StyledButton onClick={() => setPageIndex(0)}>{"<<"}</StyledButton>
          <StyledButton
            disabled={pageIndex === 0}
            onClick={() => setPageIndex(pageIndex - 1)}
          >
            {"<"}
          </StyledButton>
          <StyledButton
            disabled={subscription.length <= (pageIndex + 1) * pageSize}
            onClick={() => setPageIndex(pageIndex + 1)}
          >
            {">"}
          </StyledButton>
          <StyledButton
            onClick={() =>
              setPageIndex(Math.floor(subscription.length / pageSize))
            }
          >
            {">>"}
          </StyledButton>
        </ButtonContainer>
      </PageContainer>
    </Wrapper>
  );
};

import React, { useEffect } from "react";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box } from "@mui/material";
import { fetchUsers, selectAllUsers } from "../../modules/user/userSlice";
import { format, parseISO } from "date-fns";
import { length, head, prop, propOr, last, equals } from "ramda";
import { Wrapper, PageContainer } from "./UserStyles";
import ButtonLink from "../../lib/components/ButtonLink";
import { useNavigate } from "react-router-dom";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import Skeleton from "@mui/material/Skeleton";

export const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector(selectAllUsers);
  const userLoadingStatus = useSelector(
    (state) => state.users.userLoadingStatus
  );

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const maskPhoneNumber = (row) => {
    let maskedData = propOr("-", "phone_number", row);
    maskedData = !equals("-", maskedData)
      ? `XXXXXX${maskedData.substring(
          length(maskedData) - 4,
          length(maskedData)
        )}`
      : maskedData;
    return !equals("-", maskedData) ? `${row.country_code} ${maskedData}` : "-";
  };

  const maskEmail = (row) => {
    let maskedData = propOr("-", "email", row);
    if (!maskedData || maskedData == "-") {
      return "-";
    }
    const [firstPart, domain] = maskedData?.split("@");
    if (!firstPart || !domain) {
      return "-";
    }
    const maskedFirstPart =
      firstPart.length > 2
        ? firstPart.substring(0, 2) + "*".repeat(firstPart.length - 2)
        : firstPart.charAt(0) + "*".repeat(firstPart.length - 1);

    const [domainPrefix, domainSuffix] = domain?.split(".");
    if (!domainPrefix || !domainSuffix) {
      return "-";
    }
    const maskedDomainPrefix =
      domainPrefix.length > 2
        ? "*".repeat(domainPrefix.length - 2) + domainPrefix.slice(-2)
        : "*".repeat(domainPrefix.length);

    return maskedFirstPart + "@" + maskedDomainPrefix + "." + domainSuffix;
  };

  const columns = [
    {
      label: "ID",
      accessor: "id",
      id: "id",
      cell: ({ row }) => {
        let Id = row?.original.id?.substring(0, 10)
          ? row.original.id?.substring(0, 10)
          : "-";
        return (
          <ButtonLink
            tooltipText={row?.original?.id}
            value={Id}
            id={row?.original?.id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/users/${row?.original?.id}`);
            }}
            href={`/users/${row?.original?.id}`}
          />
        );
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.id;
        const b = rowB.original.id;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Name",
      accessorFn: (row) => {
        return row.last_name
          ? `${row.first_name} ${row.last_name}`
          : `${row.first_name}`;
      },
    },
    {
      label: "Email address",
      accessor: "email",
      id: "email",

      //Masked email hashed
      // cell: ({ row }) => {
      //   return `${maskEmail(row.original)}`;
      // },
    },
    {
      label: "Ph. Number",
      id: "number",
      accessor: "phone_number",
      //Remove this when phone number need to mask
      accessorFn: (row) => {
        return `${row.country_code} ${row.phone_number}`;
      },
      // Mask Phone hashed
      // cell: ({ row }) => {
      //   return `${maskPhoneNumber(row.original)}`;
      // },
    },
    {
      label: "Current plan",
      id: "subscription plan",
      accessorFn: (row) => {
        let subscriptionPlan = propOr(
          "-",
          "name",
          prop("SubscriptionPlan", head(row.subscriptions))
        );
        return subscriptionPlan;
      },
    },
    {
      label: "Current attribution",
      id: "currentAttribution",
      accessorFn: (row) => {
        let currentAttribution = propOr(
          "-",
          "name",
          prop("fleet", head(row.UserFleets))
        );
        return currentAttribution;
      },
    },
    {
      label: "Original attribution",
      id: "original attribution",
      accessorFn: (row) => {
        let originalAttribution = propOr(
          "-",
          "name",
          prop("fleet", last(row.UserFleets))
        );
        return originalAttribution;
      },
    },
    {
      label: " Last charged on",
      id: "last_charge",
      accessorFn: (row) => {
        let date_of_last_charging = prop(
          "created_at",
          head(row.charging_sessions)
        );
        return date_of_last_charging
          ? format(parseISO(date_of_last_charging), "dd/MM/yyyy HH:mm:ss")
          : "";
      },
      sortingFn: (rowA, rowB) => {
        const a = prop("created_at", head(rowA.original.charging_sessions));
        const b = prop("created_at", head(rowB.original.charging_sessions));
        if (a === undefined && b === undefined) {
          return 0;
        } else if (a === undefined) {
          return 1;
        } else if (b === undefined) {
          return -1;
        }

        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: " Joined on",
      id: "Created At",
      accessorFn: (row) => {
        return row.created_at
          ? format(parseISO(row.created_at), "dd/MM/yyy")
          : "";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.created_at;
        const b = rowB.original.created_at;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Deleted",
      id: "deleted_at",
      accessorFn: (row) => {
        const deleted_at = row.deleted_at;
        return deleted_at === undefined || deleted_at === null
          ? "False"
          : "True";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.deleted_at;
        const b = rowB.original.deleted_at;
        return a === null ? -1 : b === null ? 1 : a - b;
      },
    },
  ];

  const reactTableColumns = toReactTableColumns(columns);
  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          color: "#22093F",
          fontWeight: "bold",
          paddingTop: "30px",
        }}
      >
        Users
      </Typography>

      {userLoadingStatus === "Loading" ? (
        <Box sx={{ marginTop: "-8%" }}>
          <Skeleton animation="wave" width={"90%"} height={900} />
        </Box>
      ) : (
        <PageContainer>
          <GenericTable data={users} columns={reactTableColumns} />
        </PageContainer>
      )}
    </Wrapper>
  );
};

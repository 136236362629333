import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditDetailsFormDialog from "../../lib/components/EditDetailsModal";
import {
  selectUserDetails,
  updateUserDetailFleet,
  addUserDetailFleet,
} from "../../modules/user/userSlice";
import { head, propOr, prop } from "ramda";
import { fetchFleets, selectAllFleets } from "../../modules/fleets/fleetSlice";
import { SelectField } from "../../lib/components/SelectField";
const EditUserFleet = ({ open, closeModal }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  useEffect(() => {
    dispatch(fetchFleets());
  }, []);
  const fleets = useSelector(selectAllFleets);
  const [fleetName, setFleetName] = useState({
    name: propOr("-", "name", prop("fleet", head(userDetails?.UserFleets))),
    id: propOr("-", "id", prop("fleet", head(userDetails?.UserFleets))),
  });

  const onSubmit = async () => {
    const fleetRequestData = {
      id: prop("id", fleetName),
    };
    const fleetPostData = {
      user_fleet_id: prop("id", fleetName),
    };
    if (
      propOr("-", "id", prop("fleet", head(userDetails?.UserFleets))) !== "-"
    ) {
      dispatch(
        updateUserDetailFleet({
          userId: userDetails?.id,
          data: fleetRequestData,
          successCB: () => {
            closeModal(!open);
          },
          errorCB: (err) => {
            console.log(err);
          },
        })
      );
    } else {
      dispatch(
        addUserDetailFleet({
          userId: userDetails?.id,
          data: fleetPostData,
          successCB: () => {
            closeModal(!open);
          },
          errorCB: (err) => {
            console.log(err);
          },
        })
      );
    }
  };
  return (
    <EditDetailsFormDialog
      width="300px"
      open={open}
      setOpen={closeModal}
      title="Edit fleet details"
      onSubmit={onSubmit}
      content={
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "5px",
            }}
          >
            <Box>
              <SelectField
                id="fleet"
                name="Fleet"
                label="Fleet"
                value={prop("name", fleetName)}
                options={fleets}
                onSetItem={(newValue) => {
                  setFleetName({
                    ...fleetName,
                    name: prop("name", newValue),
                    id: prop("id", newValue),
                  });
                }}
              />
            </Box>
          </Box>
        </>
      }
    ></EditDetailsFormDialog>
  );
};

export default EditUserFleet;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCPODetails, getCPOs } from "../../api/cpos";

const initialState = {
  status: "idle",
  cpos: [],
  cpoDetails: "",
  cpoLoadingStatus:"",
  singleCpoLoadingStatus:""
};

export const fetchCPOs = createAsyncThunk(
  "/fetchChargingSessions",
  async () => {
    const response = await getCPOs();
    return response.data;
  }
);

export const fetchCPODetails = createAsyncThunk(
  "/fetchCPOdDetails",
  async (id) => {
    const response = await getCPODetails(id);
    return response.data;
  }
);

const cpoSlice = createSlice({
  name: "chargingSessions",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
    },
    clearStationDetails:(state) =>{
      state.cpoDetails = "";

    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCPOs.pending, (state, action) => {
        state.status = "loading";
        state.cpoLoadingStatus = "Loading"
      })
      .addCase(fetchCPOs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cpos = action.payload.data;
        state.cpoLoadingStatus = "succeeded";

      })
      .addCase(fetchCPOs.rejected, (state, action) => {
        state.status = "failed";
        state.cpoLoadingStatus = "failed";
      })
      .addCase(fetchCPODetails.pending, (state, action) => {
        state.status = "loading";
        state.singleCpoLoadingStatus = "Loading"

      })
      .addCase(fetchCPODetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cpoDetails = action.payload.data;
        state.singleCpoLoadingStatus = "succeeded";

      })
      .addCase(fetchCPODetails.rejected, (state, action) => {
        state.status = "failed";
        state.singleCpoLoadingStatus = "failed";

      });
  },
});

export const { updateState, clearStationDetails} = cpoSlice.actions;

export default cpoSlice.reducer;

export const selectAllCPOs = (state) => {
  return state.cpos.cpos;
};
export const selectCpoDetails = (state) => {
  return state.cpos.cpoDetails;
};

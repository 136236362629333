//charging session log that will display in the userdetails page
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import {
  PageContainer,
  Wrapper,
} from "../ChargingSessions/chargingSessionStyles";
import { format, parseISO } from "date-fns";
import {
  fetchChargingStationsDetails,
  selectChargingStationDetail,
} from "../../modules/chargingStation/chargingStationSlice";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";

export const StationEvseLog = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchChargingStationsDetails(id));
    // eslint-disable-next-line
  }, []);

  const stationDetails = useSelector(selectChargingStationDetail);

  const Evse = stationDetails?.evse
    ? stationDetails?.evse?.map((evse) => evse)
    : [];

  const columns = [
    {
      label: "EVSE",
      id: "evse_name",
      accessorFn: (row) => row?.evse_name,
    },
    {
      label: "Status",
      id: "status",
      accessorFn: (row) => row?.status,
    },

    {
      label: "Last used date",
      id: "updated_at",
      accessorKey: "updated_at",
      accessorFn: (row) => {
        return format(parseISO(row?.updated_at), "dd/MM/yyyy ");
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.updated_at;
        const b = rowB.original.updated_at;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Nameplate speed",
      id: "max_electric_power",
      accessorFn: (row) =>
        row?.connector[0]?.max_electric_power
          ? row?.connector[0]?.max_electric_power
          : "-",
    },
    {
      label: "Plugs",
      id: "plugs",
      accessorKey: "connector_type_value",
      accessorFn: (row) => {
        const plugs =
          row.connector &&
          row?.connector?.map((item) => item?.connector_type_value);
        return `${plugs ? plugs : "-"}`;
      },
    },
  ];

  const reactTableColumns = toReactTableColumns(columns);

  return (
    <Wrapper sx={{ background: "white" }}>
      <PageContainer sx={{ background: "white", marginTop: "-20px" }}>
        <GenericTable data={Evse} columns={reactTableColumns} />
      </PageContainer>
    </Wrapper>
  );
};

import React, { useEffect, useState } from "react";
import { Wrapper } from "../../pages/User/UserStyles";
import { Grid, Typography, Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardView from "../../lib/components/CardView";
import { head, prop, propOr } from "ramda";
import {
  fetchDetailChargingSessions,
  selectDetailChargingSession,
  clearSessionDetails,
} from "../../modules/chargingSession/chargingSessionSlice";
import ButtonLink from "../../lib/components/ButtonLink";
import Skeleton from "@mui/material/Skeleton";
import { format, parseISO } from "date-fns";

import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EditIcon from "@mui/icons-material/Edit";
import EditSessionDetailsModal from "./EditSessionDetails";
import { CommentWrapper } from "./chargingSessionStyles";

export const ChargingSessionsDetails = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const sessionDetails = useSelector(selectDetailChargingSession);
  const singleSessionLoadingStatus = useSelector(
    (state) => state.chargingSessions.singleSessionLoadingStatus
  );
  const [openSessionModal, setOpenSessionModal] = useState(false);

  const chargingSessionFirstReport =
    sessionDetails?.charging_session_reports &&
    sessionDetails?.charging_session_reports.length &&
    sessionDetails?.charging_session_reports[0];
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchDetailChargingSessions(id));
  }, [dispatch, id]);
  useEffect(() => {
    dispatch(clearSessionDetails());
    // eslint-disable-next-line
  }, []);

  const cdrOrInterim = (session) => {
    let cdrReport;
    if (session.status === "COMPLETED") {
      cdrReport = session?.charging_session_reports?.find(
        (report) => report.type === "CDR"
      );
      return cdrReport;
    } else {
      cdrReport = session?.charging_session_reports?.find(
        (report) => report.type === "INTERIM"
      );
      return cdrReport;
    }
  };

  let chargingStationReport = cdrOrInterim(sessionDetails);
  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const totalSeconds = 60;
  const formattedTime = secondsToHHMMSS(totalSeconds);
  console.log(formattedTime); // Outputs: "00:01:00"

  let totalChargingTime =
    chargingStationReport &&
    chargingStationReport?.total_charging_time !== undefined
      ? secondsToHHMMSS(chargingStationReport?.total_charging_time)
      : "-";

  let chargingTime =
    chargingStationReport &&
    chargingStationReport?.total_charging_time !== undefined
      ? chargingStationReport?.total_charging_time
      : "-";

  // let tax = chargingStationReport && propOr("-", "tax", chargingStationReport);
  let tax =
    chargingStationReport && propOr("-", "tax", chargingStationReport)
      ? `$ ${chargingStationReport.tax}`
      : "-";
  let idleFee = "-";

  if (chargingStationReport && chargingStationReport?.idle_fee !== undefined) {
    idleFee =
      chargingStationReport?.idle_fee === 0
        ? "$ 0"
        : `$ ${chargingStationReport?.idle_fee.toFixed(2)}`;
  }

  let session_fee = "-";

  if (
    chargingStationReport &&
    chargingStationReport?.session_fee !== undefined
  ) {
    session_fee =
      chargingStationReport?.session_fee === 0
        ? "$ 0"
        : `$ ${chargingStationReport?.session_fee.toFixed(2)}`;
  }

  let parking_fee = "-";

  if (
    chargingStationReport &&
    chargingStationReport?.parking_fee !== undefined
  ) {
    parking_fee =
      chargingStationReport?.parking_fee === 0
        ? "$ 0"
        : `$ ${chargingStationReport?.parking_fee.toFixed(2)}`;
  }

  let totalAmount;

  totalAmount = chargingStationReport
    ? chargingStationReport.total_energy_consumed
    : "-";
  let sessionEnergyFee = propOr("-", "session_energy_fee", sessionDetails);
  let price;
  if (sessionEnergyFee == "-" || totalAmount == "-") {
    price = "-";
  } else {
    price = sessionEnergyFee * totalAmount;
  }

  let cpo = propOr(
    "-",
    "name",
    prop("cpo", prop("charging_station", prop("evse", sessionDetails)))
  );

  let totalEnergyConsumed = totalAmount;
  let impliedEnergy;
  if (chargingTime === 0) {
    impliedEnergy = 0;
  } else if (chargingTime == "-" || totalEnergyConsumed == "-") {
    impliedEnergy = "-";
  } else {
    impliedEnergy = totalEnergyConsumed / (chargingTime / 3600);
  }
  let positive_review = propOr(
    "-",
    "positive_review",
    head(propOr([], "station_review", sessionDetails))
  );
  if (positive_review === true) {
    positive_review = <ThumbUpIcon />;
  } else if (positive_review === false) {
    positive_review = <ThumbDownIcon />;
  } else {
    positive_review = "-";
  }

  let review = prop(
    "description",
    propOr("-", "review", head(propOr([], "station_review", sessionDetails)))
  );

  let subscriptionPlan = sessionDetails?.subscription?.SubscriptionPlan?.name;
  let cost;

  cost = chargingStationReport ? chargingStationReport?.total_price : "-";

  let charginStaion =
    sessionDetails?.evse && sessionDetails?.evse?.charging_station;

  const stripeTransactionId = propOr("", "payment_intent_id", sessionDetails);

  let energy = sessionDetails?.charging_session_reports
    ? "$ " +
      sessionDetails.charging_session_reports
        .filter((item) => item.type === "CDR")
        .reduce(
          (total, item) =>
            total + item.total_price - item.parking_fee - item.session_fee,
          0
        )
        .toFixed(2)
    : "-";

  return (
    <Wrapper sx={{ marginTop: "60px" }}>
      <Grid container spacing={2}>
        {singleSessionLoadingStatus === "Loading" ? (
          <>
            <Grid item xs={4}>
              <CardView
                padding={"20px"}
                height={"70vh"}
                width={"30vw"}
                isboxShadow={true}
                title={<Skeleton height={130} width={550} />}
                content={
                  <Box sx={{ marginTop: "-25%", marginLeft: "15px" }}>
                    <Skeleton height={600} width={550} />
                  </Box>
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4}>
              <CardView
                padding={"20px"}
                width={"35vw"}
                isboxShadow={true}
                title={
                  <>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {sessionDetails?.id}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <EditIcon
                        sx={{ fontSize: 30, cursor: "pointer" }}
                        onClick={() => setOpenSessionModal(true)}
                      />
                    </Box>
                  </>
                }
                content={
                  <Grid sx={{ padding: "12px" }}>
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid grey",
                      }}
                    ></Typography>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px", marginTop: "8px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Charging Time:
                        </Typography>
                        <Typography>{totalChargingTime}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Price:
                          {chargingStationReport?.currency
                            ? `(${chargingStationReport?.currency})`
                            : ""}
                        </Typography>
                        <Typography>
                          {price === "-"
                            ? "-"
                            : `$ ${price === 0 ? "0" : price.toFixed(2)}`}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          CPO:
                        </Typography>
                        <Typography>{cpo}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Status:
                        </Typography>
                        <Typography>{sessionDetails?.status || "-"}</Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Street address:
                        </Typography>
                        <Typography>
                          {charginStaion?.street_address
                            ? charginStaion?.street_address
                            : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          City:
                        </Typography>
                        <Typography>
                          {" "}
                          {charginStaion?.city ? charginStaion?.city : "-"}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          State:
                        </Typography>
                        <Typography>
                          {charginStaion?.state ? charginStaion?.state : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Postal code:
                        </Typography>
                        <Typography>
                          {" "}
                          {charginStaion?.postal_code
                            ? charginStaion?.postal_code
                            : "-"}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Energy fee:{" "}
                          {chargingStationReport?.currency
                            ? `(${chargingStationReport?.currency})`
                            : ""}
                        </Typography>
                        <Typography>{energy}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Idle fee:{" "}
                          {chargingStationReport?.currency
                            ? `(${chargingStationReport?.currency})`
                            : ""}
                        </Typography>
                        <Typography>{idleFee}</Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Session fee:{" "}
                          {chargingStationReport?.currency
                            ? `(${chargingStationReport?.currency})`
                            : ""}
                        </Typography>
                        <Typography>{session_fee}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Parking fee:{" "}
                          {chargingStationReport?.currency
                            ? `(${chargingStationReport?.currency})`
                            : ""}
                        </Typography>
                        <Typography>{parking_fee}</Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Charging Station:
                        </Typography>
                        <Typography>
                          <ButtonLink
                            value={sessionDetails?.evse?.evse_name}
                            id={sessionDetails?.evse?.id}
                            textDecoration={"none"}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(
                                `/stations/${sessionDetails?.evse?.charging_station.id}`
                              );
                            }}
                            href={`/stations/${sessionDetails?.evse?.charging_station.id}`}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Nameplate power:
                        </Typography>
                        <Typography>
                          {sessionDetails?.evse?.connector
                            ? sessionDetails?.evse?.connector[0]
                                ?.max_electric_power + " kW"
                            : "-"}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}></Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          User:
                        </Typography>{" "}
                        <Typography>
                          <ButtonLink
                            value={`${
                              sessionDetails?.user?.last_name
                                ? sessionDetails?.user?.first_name +
                                  "" +
                                  sessionDetails.last_name
                                : sessionDetails?.user?.first_name
                            } `}
                            id={sessionDetails?.user_id}
                            textDecoration={"none"}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(`/users/${sessionDetails.user_id}`);
                            }}
                            href={`/users/${sessionDetails.user_id}`}
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Plan
                        </Typography>

                        <Typography>
                          {subscriptionPlan ? subscriptionPlan : "-"}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Cost:{" "}
                          {chargingStationReport?.currency
                            ? `(${chargingStationReport?.currency})`
                            : ""}
                        </Typography>
                        <Typography>
                          {/* {"$ "} {cost == "-" ? "-" : cost === 0 ? 0 : cost} */}
                          {cost === "-" ? "-" : `$ ${cost === 0 ? 0 : cost}`}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Fleet / Fleet Segment:
                        </Typography>
                        <Typography>
                          {sessionDetails?.user?.UserFleets &&
                          sessionDetails?.user?.UserFleets[0]
                            ? sessionDetails?.user?.UserFleets[0]?.fleet?.name
                            : "-"}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Receipt:
                        </Typography>
                        <Typography>{sessionDetails?.ReceiptID}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Energy Dispensed:
                        </Typography>
                        <Typography>
                          {chargingStationReport?.total_energy_consumed
                            ? chargingStationReport?.total_energy_consumed +
                              " kWh"
                            : "-"}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Time Stamp:
                        </Typography>
                        <Typography>
                          {sessionDetails?.start_time
                            ? format(
                                parseISO(sessionDetails?.start_time),
                                "dd/MM/yyyy HH:mm:ss"
                              )
                            : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Implied Power
                        </Typography>

                        <Typography>
                          {impliedEnergy == "-"
                            ? "-"
                            : impliedEnergy === 0
                            ? 0
                            : impliedEnergy.toFixed(2) + " kW"}
                        </Typography>
                      </Box>
                    </Stack>
                    {/* new  */}

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Tax:{" "}
                        </Typography>
                        <Typography>{tax}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Stripe transaction id:
                        </Typography>

                        <Typography>
                          {" "}
                          <ButtonLink
                            value={`${stripeTransactionId}`}
                            id={sessionDetails?.user_id}
                            textDecoration={"none"}
                            onClick={() => {
                              navigate(
                                `https://dashboard.stripe.com/payments/${stripeTransactionId}`
                              );
                            }}
                            target="_blank"
                            href={`https://dashboard.stripe.com/payments/${stripeTransactionId}`}
                          />
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Session Rating:
                        </Typography>
                        <Typography>{positive_review}</Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Session Comment
                        </Typography>
                        <CommentWrapper>
                          <Typography
                            sx={{ maxWidth: "200px", overflow: "hidden" }}
                          >
                            {review ? review : "-"}
                          </Typography>
                        </CommentWrapper>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Notes:
                          <CommentWrapper>
                            <Typography
                              sx={{ maxWidth: "200px", overflow: "hidden" }}
                            >
                              {propOr("-", "notes", sessionDetails)}
                            </Typography>
                          </CommentWrapper>
                        </Typography>
                      </Box>
                    </Stack>

                    {/* end */}
                  </Grid>
                }
              ></CardView>
            </Grid>
          </>
        )}
      </Grid>
      {openSessionModal && (
        <>
          <EditSessionDetailsModal
            open={openSessionModal}
            closeModal={setOpenSessionModal}
          />
        </>
      )}
    </Wrapper>
  );
};

import { Link } from "@mui/material";
import { Box } from "@mui/material";

const ButtonLink = ({
  id,
  textDecoration,
  value,
  onClick,
  tooltipText,
  href,
  target,
}) => {
  return (
    <Box title={tooltipText}>
      <Link
        variant={"body2"}
        id={`buttonlink.${id}`}
        onClick={onClick}
        underline={textDecoration}
        sx={{
          color: "#7531C5",
          fontWeight: 600,
        }}
        href={href}
        target={target}
      >
        {value}
      </Link>
    </Box>
  );
};

export default ButtonLink;

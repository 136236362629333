import React, { useEffect, useState } from "react";
import { Wrapper } from "./UserStyles";
import { Avatar, Grid, Typography, Stack, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  fetchUsersDetail,
  selectUserDetails,
  clearUserDetails,
} from "../../modules/user/userSlice";
import { format, parseISO } from "date-fns";

import { useDispatch, useSelector } from "react-redux";
import CardView from "../../lib/components/CardView";
import AccordionTable from "../../lib/components/AccordianTable/Index";
import { ChargingSessionLog } from "./ChargingSessionLog";
import { head, prop, propOr, last, length, equals } from "ramda";
import { PlanPurchase } from "./PlanPurchase";
import EditUserDetails from "./EditUserDetails";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import Skeleton from "@mui/material/Skeleton";
import EditUserFleet from "./EditUserFleet";

export const SingleUser = () => {
  const dispatch = useDispatch();
  const details = useSelector(selectUserDetails);
  const [openModal, setOpenModal] = useState(false);
  const singleUserLoadingStatus = useSelector(
    (state) => state.users.singleUserLoadingStatus
  );
  const [openEditFleetModal, setOpenEditFleetModal] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchUsersDetail(id));
  }, [dispatch, id, openModal, openEditFleetModal]);

  useEffect(() => {
    dispatch(clearUserDetails());
    // eslint-disable-next-line
  }, []);

  let currentAttribution =
    details?.UserFleets &&
    propOr("-", "name", prop("fleet", head(details?.UserFleets)));

  let originalAttribution =
    details?.UserFleets &&
    propOr("-", "name", prop("fleet", last(details?.UserFleets)));

  const maskPhoneNumber = (details) => {
    let maskedData = propOr("-", "phone_number", details);
    maskedData = !equals("-", maskedData)
      ? `XXXXXX${maskedData.substring(
          length(maskedData) - 4,
          length(maskedData)
        )}`
      : maskedData;
    return !equals("-", maskedData)
      ? `${details.country_code} ${maskedData}`
      : "-";
  };

  const maskEmail = (email) => {
    if (!email || email == "-") {
      return "-";
    }
    const [firstPart, domain] = email?.split("@");
    if (!firstPart || !domain) {
      return "-";
    }
    const maskedFirstPart =
      firstPart?.length > 2
        ? firstPart.substring(0, 2) + "*".repeat(firstPart?.length - 2)
        : firstPart.charAt(0) + "*".repeat(firstPart?.length - 1);

    const [domainPrefix, domainSuffix] = domain.split(".");
    if (!domainPrefix || !domainSuffix) {
      return "-";
    }
    const maskedDomainPrefix =
      domainPrefix?.length > 2
        ? "*".repeat(domainPrefix?.length - 2) + domainPrefix.slice(-2)
        : "*".repeat(domainPrefix?.length);

    return maskedFirstPart + "@" + maskedDomainPrefix + "." + domainSuffix;
  };

  let deviceOSVerion = propOr(
    "-",
    "deviceOSversion",
    head(propOr([], "guest", details))
  );
  const parts = deviceOSVerion.split(":");
  deviceOSVerion = parts[0].trim();
  return (
    <Wrapper sx={{ marginTop: "60px" }}>
      <Grid container spacing={2}>
        {singleUserLoadingStatus === "Loading" && details?.length === 0 ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={4} sx={{ height: "100%" }}>
                <CardView
                  padding={"20px"}
                  width={"27vw"}
                  isboxShadow={true}
                  title={
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Skeleton variant="circular" width={100} height={100} />
                    </Box>
                  }
                  content={<Skeleton height={800} />}
                />
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ marginTop: "-4%", marginLeft: "25%" }}>
                  <Skeleton height={130} width={750} />
                  <Skeleton height={130} width={750} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Grid sx={{ height: "100%" }} item xs={4}>
              <CardView
                padding={"20px"}
                width={"27vw"}
                isboxShadow={true}
                title={
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Avatar
                      sx={{
                        bgcolor: "#22093F",
                        height: "90px",
                        width: "90px",
                        padding: "6px",
                        marginTop: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "30px",
                          textTransform: "uppercase",
                        }}
                      >
                        {details?.last_name
                          ? details?.first_name?.charAt(0) +
                            details.last_name.charAt(0)
                          : details?.first_name?.charAt(0)}
                      </Typography>
                    </Avatar>
                  </Box>
                }
                content={
                  <Grid sx={{ padding: "14px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        {details?.last_name
                          ? details?.first_name + " " + details.last_name
                          : details?.first_name}
                      </Typography>
                      {details !== null ? (
                        <EditIcon
                          sx={{ fontSize: 30, cursor: "pointer" }}
                          onClick={() => setOpenModal(true)}
                        />
                      ) : null}
                    </Box>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{
                        paddingBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        padding: "10px 0px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <EmailIcon sx={{ fontSize: 20 }} />

                        <Typography
                          sx={{
                            paddingBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {/* {maskEmail(details?.email)} */}
                          {details?.email}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", gap: "5px" }}>
                        <PhoneIcon sx={{ fontSize: 20 }} />

                        <Typography
                          sx={{
                            paddingBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {/* {phone_number masked hashed} */}
                          {/* {maskPhoneNumber(details)} */}
                          {details?.country_code} {details?.phone_number}
                        </Typography>
                      </Box>
                    </Stack>
                    <Box sx={{ display: "flex", gap: "5px" }}>
                      <Typography
                        sx={{
                          paddingBottom: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        ID
                      </Typography>
                      <Typography>{details?.id}</Typography>
                    </Box>
                    <hr />

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ padding: "15px 0px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Plan name:
                        </Typography>
                        <Typography>
                          {propOr(
                            "-",
                            "name",
                            prop(
                              "SubscriptionPlan",
                              head(propOr([], "subscriptions", details))
                            )
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Total number of purchases:
                        </Typography>
                        <Typography>
                          {length(propOr([], "subscriptions", details))}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Balance energy in Plan:
                        </Typography>
                        <Typography>
                          {(head(propOr([], "subscriptions", details))
                            ?.remaining_energy !== undefined &&
                            head(
                              propOr([], "subscriptions", details)
                            )?.remaining_energy.toFixed(2) + " kWh") ||
                            "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Valid through:
                        </Typography>
                        <Typography>
                          {head(propOr([], "subscriptions", details))
                            ?.renewal_date_time
                            ? format(
                                parseISO(
                                  head(propOr([], "subscriptions", details))
                                    ?.renewal_date_time
                                ),
                                "dd/MM/yyyy HH:mm"
                              )
                            : "-"}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Created date:
                        </Typography>
                        <Typography>
                          {details?.created_at
                            ? format(
                                parseISO(details?.created_at),
                                "dd/MM/yyy "
                              )
                            : ""}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Last active date :
                        </Typography>
                        <Typography>
                          {details?.updated_at
                            ? format(
                                parseISO(details?.updated_at),
                                "dd/MM/yyy "
                              )
                            : ""}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{ paddingBottom: "10px", fontWeight: "bold" }}
                        >
                          Version:
                        </Typography>
                        <Typography>{deviceOSVerion}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ paddingBottom: "10px", fontWeight: "bold" }}
                        >
                          OS:
                        </Typography>
                        <Typography>
                          {propOr(
                            "-",
                            "deviceOS",
                            head(propOr([], "guest", details))
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Original attribution :
                        </Typography>

                        <Typography>{originalAttribution}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Current attribution :
                          <EditIcon
                            sx={{ fontSize: 16, cursor: "pointer" }}
                            onClick={() => setOpenEditFleetModal(true)}
                          />
                        </Typography>
                        <Typography>{currentAttribution}</Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          Make:
                        </Typography>
                        <Typography>
                          {propOr(
                            "-",
                            "make",
                            prop(
                              "vehicle",
                              head(propOr([], "UserVehicle", details))
                            )
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Model:
                        </Typography>
                        <Typography>
                          {propOr(
                            "-",
                            "model",
                            prop(
                              "vehicle",
                              head(propOr([], "UserVehicle", details))
                            )
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Last 4 digits of card:
                        </Typography>
                        <Typography>{details?.check_digit}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                }
              ></CardView>
            </Grid>

            <Grid item xs={8}>
              <AccordionTable
                summary={
                  <Typography variant="h6"> Charging Sessions</Typography>
                }
                details={
                  <>
                    <Grid sx={{ width: "90%", background: "white" }}>
                      <ChargingSessionLog />
                    </Grid>
                  </>
                }
              />
              <AccordionTable
                summary={
                  <Typography variant="h6">Plan purchase history</Typography>
                }
                details={
                  <Grid sx={{ width: "90%", background: "white" }}>
                    <PlanPurchase />
                  </Grid>
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      {openModal && (
        <>
          <EditUserDetails open={openModal} closeModal={setOpenModal} />
        </>
      )}
      {openEditFleetModal && (
        <>
          <EditUserFleet
            open={openEditFleetModal}
            closeModal={setOpenEditFleetModal}
          />
        </>
      )}
    </Wrapper>
  );
};

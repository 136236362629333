import Form from "./pages/Login/Login";
import { Route, Routes, useLocation } from "react-router-dom";
import { User } from "./pages/User/User";
import { CPOs } from "./pages/Cpos/Cpos";
import { HomePage } from "./pages/Home/HomePage";
import { ChargingSessions } from "./pages/ChargingSessions/chargingSessions";
import { ChargingStations } from "./pages/ChargingStations/chargingStations";
import { SubscriptionPlans } from "./pages/SubscriptionPlans/SubscriptionPlans";
import PrivateOutlet from "./utils/PrivateOutlet";
import { Wrapper, PageContainer } from "./AppStyles";
import AppBar from "./lib/components/AppBar";
import { SingleUser } from "./pages/User/UserDetails";
import { ChargingStaionDetails } from "./pages/ChargingStations/ChargingStationDetails";
import { ChargingSessionsDetails } from "./pages/ChargingSessions/ChargingSessionsDetails";
import { CpoDetails } from "./pages/Cpos/CpoDetails";
import { SubscriptionPlanDetails } from "./pages/SubscriptionPlans/SubscriptionPlanDetails";
import { Fleets } from "./pages/Fleets/Fleets";
import { FleetDetails } from "./pages/Fleets/FleetDetails";
import PublicOutlet from "./utils/PublicOutlet";
function App() {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" ? (
        <Routes>
          <Route path="/" element={<PublicOutlet />}>
            <Route path="" element={<Form />} />
          </Route>
        </Routes>
      ) : (
        <Wrapper>
          {location.pathname !== "/" && <AppBar />}

          <PageContainer>
            <Routes>
              <Route path="/users" element={<PrivateOutlet />}>
                <Route path="" element={<User />} />
              </Route>
              <Route element={<PrivateOutlet />}>
                <Route path="/dashboard" element={<HomePage />} />
                <Route path="/stations" element={<ChargingStations />} />
                <Route path="/cpos" element={<CPOs />} />
                <Route path="/sessions" element={<ChargingSessions />} />
                <Route path="/plans" element={<SubscriptionPlans />} />
                <Route path="/users/:id/*" element={<SingleUser />} />
                <Route
                  path="/sessions/:id/*"
                  element={<ChargingSessionsDetails />}
                />
                <Route
                  path="/stations/:id/*"
                  element={<ChargingStaionDetails />}
                />
                <Route path="/cpos/:id/*" element={<CpoDetails />} />
                <Route
                  path="/plans/:id"
                  element={<SubscriptionPlanDetails />}
                />
                <Route path="/fleets" element={<Fleets />} />
                <Route path="/fleets/:id/*" element={<FleetDetails />} />
              </Route>
            </Routes>
          </PageContainer>
        </Wrapper>
      )}
    </>
  );
}

export default App;

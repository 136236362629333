import styled from "styled-components";

export const TableDiv = styled.div`
  border-radius: 16px 16px 0 0;
  width: 90%;
  table {
    width: 100%;
    border-spacing: 0;
    border-radius: 8px;
    /* height: 10vh;     */
    background-color: white;
    overflow: hidden;
    /* /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  thead {
    background: #22093f;
    color: #ffffff;
    position: sticky;
    top: 0;

    th {
      text-align: left;
      /* font-weight: 500; */
    }
  }
  td {
    padding: 16px 24px;
    border-bottom: 1px solid #d6d6d6;

    &:nth-child(1) {
      font-weight: 700;
    }
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    height: 10px;
  }

  th {
    font-weight: bold;
    background-color: #4e3a65;
    font-size: 18px; /* Increase font size for thead */
    color: #fff; /* Text color for thead */
  }

  tr:hover {
    /* background-color: #f9f9f9; */
    background-color: lightgrey;
  }
`;

// Styled Buttons
const StyledButton = styled.button`
  width: 10%;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
  color: #22093f;
  background-color: #f2f2f2;
  border: none;
  padding: 8px 12px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;
const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

// export const StyledButton=styled.div`
// border: 1px solid black;
// display: flex;
// `

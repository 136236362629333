import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

export const SelectField = ({
  id,
  label,
  name,
  onSetItem,
  value,
  options,
  width,
}) => {
  return (
    <>
      <FormControl sx={{ m: 1, minWidth: width ? width : 220 }}>
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
        <Select
          label={label}
          labelId="demo-simple-select-label"
          id={id}
          name={name}
          onChange={(e) => {
            onSetItem(e.target.value);
          }}
          value={value}
          renderValue={(val) => val}
        >
          {options?.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSubscriptionPlanList,
  getSubscriptionPlanDetails,
  updateSubscriptionPlanDetail,
} from "../../api/subscriptionPlan";

const initialState = {
  status: "idle",
  subscriptionPlans: [],
  subscriptionPlanswithFleetId: [],
  subscriptionPlanDetails: "",
  subscriptionLoadingStatus: "",
  singleSubscriptionLoading: "",
};

export const fetchSubscriptionPlans = createAsyncThunk(
  "/fetchSubscriptionPlans",
  async (fleet_id) => {
    const response = await getSubscriptionPlanList(fleet_id);
    return response.data;
  }
);

export const fetchSubscriptionPlanswithFleetID = createAsyncThunk(
  "/fetchSubscriptionPlanswithFleetID",
  async (fleet_id) => {
    const response = await getSubscriptionPlanList(fleet_id);
    return response.data;
  }
);

export const fetchSubscriptionPlanDetails = createAsyncThunk(
  "/fetchSubscriptionDetails",
  async (id) => {
    const response = await getSubscriptionPlanDetails(id);
    return response.data;
  }
);

export const updateSubscriptionsPlanDetail = createAsyncThunk(
  "/updateSubscriptionDetails",
  async ({ id, data, successCB, errorCB }) => {
    const response = await updateSubscriptionPlanDetail(
      id,
      data,
      successCB,
      errorCB
    );
    successCB(response.data);
    return response.data;
  }
);

const fetchSubscriptionPlansSlice = createSlice({
  name: "subscriptionPlans",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
    },
    clearSubscriptionDetails: (state) => {
      state.subscriptionPlanDetails = "";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSubscriptionPlans.pending, (state, action) => {
        state.status = "loading";
        state.subscriptionLoadingStatus = "Loading";
      })
      .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptionPlans = action.payload.data;
        state.subscriptionLoadingStatus = "succeeded";
      })
      .addCase(fetchSubscriptionPlans.rejected, (state, action) => {
        state.status = "failed";
        state.subscriptionLoadingStatus = "failed";
        state.subscriptionPlans = [];
      })
      .addCase(fetchSubscriptionPlanswithFleetID.pending, (state, action) => {
        state.status = "loading";
        state.subscriptionLoadingStatus = "Loading";
      })
      .addCase(fetchSubscriptionPlanswithFleetID.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptionPlanswithFleetId = action.payload.data;
        state.subscriptionLoadingStatus = "succeeded";
      })
      .addCase(fetchSubscriptionPlanswithFleetID.rejected, (state, action) => {
        state.status = "failed";
        state.subscriptionLoadingStatus = "failed";
      })
      .addCase(fetchSubscriptionPlanDetails.pending, (state, action) => {
        state.status = "loading";
        state.singleSubscriptionLoading = "loading";
      })
      .addCase(fetchSubscriptionPlanDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptionPlanDetails = action.payload.data;
        state.singleSubscriptionLoading = "succeeded";
      })
      .addCase(fetchSubscriptionPlanDetails.rejected, (state, action) => {
        state.status = "failed";
        state.singleSubscriptionLoading = "failed";
      })
      .addCase(updateSubscriptionsPlanDetail.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateSubscriptionsPlanDetail.fulfilled, (state, action) => {
        state.subscriptionPlanswithFleetId = action.payload.data;
        state.status = "succeeded";
      })
      .addCase(updateSubscriptionsPlanDetail.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { updateState, clearSubscriptionDetails } =
  fetchSubscriptionPlansSlice.actions;

export default fetchSubscriptionPlansSlice.reducer;

export const selectAllSubscriptionPlansSlice = (state) =>
  state.subscriptionPlans.subscriptionPlans;

export const selectAllSubscriptionPlanswithFleetIDSlice = (state) =>
  state.subscriptionPlans.subscriptionPlanswithFleetId;

export const selectSubscriptionPlanDetails = (state) =>
  state.subscriptionPlans.subscriptionPlanDetails;

import React, { useEffect } from "react";
import { parse } from "date-fns";

export const Filter = ({ column }) => {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export function dateBetweenFilterFn(row, columnId, value) {
  try {
    if (!row || !columnId || !value || value.length !== 2) {
      return false; // Handle invalid input data gracefully
    }

    const sd = value[0] ? new Date(value[0]) : undefined;
    const ed = value[1] ? new Date(value[1]) : undefined;

    var cellDate = parse(
      row.getValue(columnId),
      "dd/MM/yyyy HH:mm:ss",
      new Date()
    );

    // Set the time to midnight (00:00:00)
    cellDate.setHours(0, 0, 0, 0);

    // Ensure that sd and ed also have their time portions set to midnight
    if (sd) {
      sd.setHours(0, 0, 0, 0);
    }
    if (ed) {
      ed.setHours(0, 0, 0, 0);
    }

    if (ed && sd) {
      return cellDate >= sd && cellDate <= ed;
    } else if (sd) {
      return cellDate >= sd;
    } else {
      return cellDate <= ed;
    }
  } catch (error) {
    console.error("Error in dateBetweenFilterFn:", error);
    return false; // Handle parsing errors gracefully
  }
}

export function DateRangeColumnFilter({ column, initialValue = [] }) {
  const [startDate, setStartDate] = React.useState(
    initialValue.length === 2 ? initialValue[0] : ""
  );
  const [endDate, setEndDate] = React.useState(
    initialValue.length === 2 ? initialValue[1] : ""
  );
  useEffect(() => {
    column.setFilterValue(() => "");
  }, []);
  return (
    <div style={{ paddingRight: "20px" }}>
      <input
        onChange={(e) => {
          setStartDate(e.target.value);
          column.setFilterValue((old = []) => {
            if (!e.target.value && !old[1]) {
              return "";
            } else {
              return [e.target.value || undefined, old[1]];
            }
          });

          // column.setFilterValue(e.target.value)
        }}
        onClick={(e) => e.target.showPicker()}
        type="date"
        value={startDate}
        style={{
          height: "3vh",
          borderRadius: "8px",
          borderColor: "#4e3a65",
          padding: "10px",
          fontSize: "16px",
        }}
      />

      <strong style={{ color: "black", paddingRight: "1px" }}> TO </strong>

      <input
        onChange={(e) => {
          setEndDate(e.target.value);
          column.setFilterValue((old = []) => {
            if (!e.target.value && !old[0]) {
              return "";
            } else {
              return [old[0], e.target.value || undefined];
            }
          });
        }}
        onClick={(e) => e.target.showPicker()}
        type="date"
        value={endDate}
        style={{
          height: "3vh",
          borderRadius: "8px",
          borderColor: "#4e3a65",
          padding: "10px",
          fontSize: "16px",
        }}
      />
    </div>
  );
}

export const DefaultColumnFilter = ({
  column: { filterValue, setFilterValue, preFilteredRows = [] },
}) => {
  // Check if preFilteredRows is defined and get its length
  const rowCount = preFilteredRows ? preFilteredRows.length : 0;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilterValue(e.target.value || undefined);
      }}
      placeholder={`Buscar (${rowCount}) ...`}
    ></input>
  );
};

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditDetailsFormDialog from "../../lib/components/EditDetailsModal";
import {
  selectUserDetails,
  updateUsersDetail,
} from "../../modules/user/userSlice";
import { TextInputField } from "../../lib/components/TextField";
import { head, propOr, prop } from "ramda";
import { format, parseISO, parse } from "date-fns";
import { DatePickerField } from "../../lib/components/DatePicker";
const EditUserDetails = ({ open, closeModal }) => {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();
  const [modifiedUserDetails, setmodifiedUserDetails] = useState(userDetails);
  const [error, SetError] = useState({});
  const [remainingEnergy, setRemainingEnergy] = useState(
    head(propOr([], "subscriptions", userDetails))?.remaining_energy.toFixed(2)
  );

  const [renewalDateTime, setRenewalDateTime] = useState(
    head(propOr([], "subscriptions", userDetails))?.renewal_date_time &&
      format(
        parseISO(
          head(propOr([], "subscriptions", userDetails))?.renewal_date_time
        ),
        "MM/dd/yyyy HH:mm"
      )
  );
  const subscriptionType = propOr(
    "-",
    "subscription_type",
    prop(
      "SubscriptionPlan",
      head(propOr([], "subscriptions", modifiedUserDetails))
    )
  );
  const onSubmit = async () => {
    const requestData = {
      first_name: modifiedUserDetails.first_name,
      email: modifiedUserDetails.email,
      remaining_energy: remainingEnergy,
      renewal_date_time: renewalDateTime
        ? format(
            parse(renewalDateTime, "MM/dd/yyyy HH:mm", new Date()),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          )
        : null,
      subscription_id: modifiedUserDetails.subscriptions[0]?.id,
    };

    dispatch(
      updateUsersDetail({
        userId: userDetails?.id,
        data: requestData,
        successCB: () => {
          closeModal(!open);
          SetError({ ...error, email: "" });
        },
        errorCB: (err) => {
          SetError({ ...error, email: err.status_message });
        },
      })
    );
  };
  const planName = propOr(
    "-",
    "name",
    prop("SubscriptionPlan", head(propOr([], "subscriptions", userDetails)))
  );

  useEffect(() => {
    SetError({ ...error, email: "" });
  }, [modifiedUserDetails.email]);

  return (
    <EditDetailsFormDialog
      width="350px"
      height="35vh"
      open={open}
      setOpen={closeModal}
      title="Edit user details"
      onSubmit={onSubmit}
      content={
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              padding: "5px",
            }}
          >
            <Typography sx={{ color: "#22093F" }}>
              Personal information
            </Typography>
            <Box>
              <TextInputField
                id="name"
                fullWidth
                name="name"
                label={"Name"}
                value={modifiedUserDetails.first_name}
                onSetItem={(newValue) => {
                  setmodifiedUserDetails({
                    ...modifiedUserDetails,
                    first_name: newValue,
                  });
                }}
              />
            </Box>
            <Box>
              <TextInputField
                id="email"
                fullWidth
                name="email"
                label={"Email"}
                value={modifiedUserDetails.email}
                onSetItem={(newValue) => {
                  setmodifiedUserDetails({
                    ...modifiedUserDetails,
                    email: newValue,
                  });
                }}
              />
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {error.email ? error.email : null}
              </Typography>
            </Box>
            {planName !== "" && planName !== "-" ? (
              <>
                <Typography sx={{ color: "#22093F" }}>
                  {"Subscription: "}
                  {`${planName}`}
                </Typography>
                <Box>
                  <TextInputField
                    id="remaining_energy"
                    fullWidth
                    name="remaining_energy"
                    label={"Balance energy in Plan"}
                    value={remainingEnergy}
                    onSetItem={(newValue) => {
                      setRemainingEnergy(newValue);
                    }}
                    isDisabled={subscriptionType === "PAYASYOUGO"}
                  />
                </Box>
                <Box>
                  <DatePickerField
                    width="235px"
                    id="renewal_date_time"
                    name="renewal_date_time"
                    label={"Valid through"}
                    value={renewalDateTime ? renewalDateTime : null}
                    onSetItem={(newValue) => {
                      setRenewalDateTime(newValue);
                    }}
                    isDisabled={subscriptionType === "PAYASYOUGO"}
                  />
                </Box>
              </>
            ) : null}
          </Box>
        </>
      }
    ></EditDetailsFormDialog>
  );
};

export default EditUserDetails;

//charging session log that will display in the userdetails page
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import {
  PageContainer,
  Wrapper,
} from "../ChargingSessions/chargingSessionStyles";
import { useDispatch } from "react-redux";

import {
  fetchChargingSessions,
  selectAllChargingSessions,
} from "../../modules/chargingSession/chargingSessionSlice";

import { format, parseISO } from "date-fns";
import { head, prop, propOr, replace, toUpper, toLower } from "ramda";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ButtonLink from "../../lib/components/ButtonLink";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

export const ChargingSessionLog = () => {
  const dispatch = useDispatch();
  const chargingSessions = useSelector(selectAllChargingSessions);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchChargingSessions(id));
  }, [dispatch, id]);

  const cdrOrInterim = (row) => {
    let cdrReport;
    if (row.status === "COMPLETED") {
      cdrReport = row?.charging_session_reports?.find(
        (report) => report.type === "CDR"
      );
      return cdrReport;
    } else {
      cdrReport = row?.charging_session_reports?.find(
        (report) => report.type === "INTERIM"
      );
      return cdrReport;
    }
  };
  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const totalSeconds = 60;
  const formattedTime = secondsToHHMMSS(totalSeconds);
  console.log(formattedTime); // Outputs: "00:01:00"
  const columns = [
    {
      label: "ID",
      id: "id",
      accessor: "id",
      cell: ({ row }) => {
        let value = row?.original.id?.substring(0, 10)
          ? row.original.id?.substring(0, 10)
          : "-";

        return (
          <ButtonLink
            tooltipText={row.original.id}
            value={value}
            id={row.original.id}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/sessions/${row.original.id}`);
            }}
            href={`/sessions/${row.original.id}`}
          />
        );
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.id;
        const b = rowB.original.id;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Time Stamp",
      id: "start_time",
      filterFn: "datefilter",
      accessorFn: (row) => {
        return row.start_time
          ? format(parseISO(row.start_time), "dd/MM/yyyy HH:mm:ss")
          : "";
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.start_time;
        const b = rowB.original.start_time;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Charging Time",
      id: "total_charging_time",
      accessorFn: (row) => {
        let totalChargingTime = cdrOrInterim(row);
        return `${
          totalChargingTime &&
          totalChargingTime?.total_charging_time !== undefined
            ? secondsToHHMMSS(totalChargingTime?.total_charging_time)
            : "-"
        }`;
      },
    },
    {
      label: "Energy Dispensed",
      accessorKey: "total_energy_consumed",
      id: "total_energy_consumed",
      accessorFn: (row) => {
        let totalEnergyConsumed = cdrOrInterim(row);
        return `${
          totalEnergyConsumed &&
          totalEnergyConsumed?.total_energy_consumed !== undefined
            ? totalEnergyConsumed?.total_energy_consumed + " kWh"
            : "-"
        }`;
      },
    },
    {
      label: "Implied Power",
      id: "implied_energy",
      accessorFn: (row) => {
        let totalEnergyConsumed = cdrOrInterim(row);
        totalEnergyConsumed =
          totalEnergyConsumed &&
          totalEnergyConsumed?.total_energy_consumed !== undefined
            ? totalEnergyConsumed?.total_energy_consumed
            : "-";

        let chargingDuration = cdrOrInterim(row);
        chargingDuration =
          chargingDuration &&
          chargingDuration?.total_charging_time !== undefined
            ? chargingDuration?.total_charging_time
            : "-";
        let impliedEnergy;
        if (chargingDuration === 0) {
          impliedEnergy = 0;
        } else if (chargingDuration === "-" || totalEnergyConsumed == "-") {
          impliedEnergy = "-";
        } else {
          impliedEnergy = totalEnergyConsumed / (chargingDuration / 3600);
        }
        return `${
          impliedEnergy == "-"
            ? "-"
            : impliedEnergy === 0
            ? 0
            : impliedEnergy.toFixed(2) + " kW"
        }`;
      },
    },
    {
      label: "Price",
      id: "total_price",
      accessorFn: (row) => {
        let sessionEnergyFee = propOr("-", "session_energy_fee", row);
        const cdrReport = cdrOrInterim(row);
        let totalAmount = cdrReport ? cdrReport.total_energy_consumed : "-";
        let price;
        if (sessionEnergyFee == "-" || totalAmount == "-") {
          price = "-";
        } else {
          price = sessionEnergyFee * totalAmount;
        }
        // return `${price == "-" ? "-" : price === 0 ? 0 : price.toFixed(2)}`;
        if (price !== "-") {
          price = `$ ${price === 0 ? 0 : price.toFixed(2)}`;
        }
        return price;
      },
    },
    {
      label: "CPO",
      id: "cpo",
      accessorFn: (row) => {
        let cpo = propOr(
          "-",
          "name",
          prop("cpo", prop("charging_station", prop("evse", row)))
        );
        return `${cpo}`;
      },
    },
    {
      label: "Status",
      accessorFn: (row) => {
        const status = toLower(propOr("-", "status", row));
        const capitalize = replace(/^./, toUpper);
        return capitalize(status);
      },
      id: "status",
    },
    {
      label: "Session Rating",
      id: "station_reviews",
      cell: ({ row }) => {
        const positive_review = propOr(
          "-",
          "positive_review",
          head(propOr([], "station_reviews", row.original))
        );
        return positive_review === true ? (
          <ThumbUpIcon />
        ) : positive_review === false ? (
          <ThumbDownIcon />
        ) : (
          "-"
        );
      },
      enableSorting: false,
    },
    {
      label: "Session Comments",
      id: "review",
      accessorFn: (row) => {
        let review = prop(
          "description",
          propOr("-", "review", head(propOr([], "station_reviews", row)))
        );
        return review;
      },
    },
  ];
  const reactTableColumns = toReactTableColumns(columns);

  return (
    <Wrapper sx={{ background: "white" }}>
      <PageContainer sx={{ background: "white", marginTop: "-20px" }}>
        <GenericTable
          data={chargingSessions || []}
          columns={reactTableColumns}
        />
      </PageContainer>
    </Wrapper>
  );
};

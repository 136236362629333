import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")({
  backgroundColor: "#d7dde7",
  display: "flex",
  width: "100%",
  maxHeight: "100%",
  color: "#ffffff",
  flexDirection: "column",
  padding: "0 5%",
});

export const PageContainer = styled("div")({
  backgroundColor: "#d7dde7",
  overflow: "auto",
  maxHeight: "100%",
});

export const CommentWrapper = styled("div")({
  overflowWrap: "break-word",
  overflowY: "scroll",
  overflowX: "hidden",
  width: "90%",
  height: "150px",
  "::-webkit-scrollbar-thumb": {
    background: "white", 
    borderRadius: "5px", 
  },
  "::-webkit-scrollbar": {
    width: "10px",
    height: "8px",
    backgroundColor: "#fff"
  }
  
});

import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";

export const MenuButtonWrapper = styled(Link, {
  shouldForwardProp: (prop) => prop !== "selected",
})(
  ({ selected }) => `
    align-items: center;
    background-color: ${selected ? "#2fe080" : "inherit"};
    width:9.8rem;
    height: 4.8rem;
    border-right: solid 1px grey;
    color: ${selected ? "#1b1b1b" : "inherit"};
    position: relative;
    text-decoration: none;
    justify-content: space-evenly;
    display: flex;
    cursor: pointer;
    `
);

export const MenuIcons = styled("div", {
  shouldForwardProp: (prop) => prop !== "selected",
})(
  ({ selected }) => `
    display: flex;
    justify-content: center;
    color: ${selected ? "#1b1b1b" : "inherit"};
    svg {
        width: 30px;
        height: 30px;
    }
    `
);

export const MenuLabel = styled("div", {
  shouldForwardProp: (prop) => prop !== "selected",
})(
  ({ selected }) => `
      color: ${selected ? "#1b1b1b" : "inherit"};
      font-size: 0.9rem;
      display: flex;
      `
);

import { useNavigate, useLocation } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import ChargingStationOutlinedIcon from "@mui/icons-material/ChargingStationOutlined";
import BatteryCharging90OutlinedIcon from "@mui/icons-material/BatteryCharging90Outlined";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { Stack } from "@mui/material";
import { MenuButtonWrapper, MenuIcons, MenuLabel } from "./TabsStyles";
import { includes } from "ramda";

const pages = [
  { name: "Dashboard", path: "/dashboard", icon: <HomeOutlinedIcon /> },
  { name: "Users", path: "/users", icon: <Person2OutlinedIcon /> },
  {
    name: "Sessions",
    path: "/sessions",
    icon: <BatteryCharging90OutlinedIcon />,
  },

  {
    name: "Plans",
    path: "/plans",
    icon: <ListAltOutlinedIcon />,
  },
  {
    name: "Fleets",
    path: "/fleets",
    icon: <ElectricCarIcon />,
  },
  {
    name: "Stations",
    path: "/stations",
    icon: <ChargingStationOutlinedIcon />,
  },
  { name: "CPOs", path: "/cpos", icon: <EvStationOutlinedIcon /> },
];

const isMenuSelected = (pathname, pagePath) => {
  return includes(pagePath, pathname);
};

const Tabs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Stack direction={"row"}>
      {pages.map((page) => (
        <Stack
          direction={"row"}
          style={{ justifyContent: "center", alignItems: "center" }}
          key={page.name}
        >
          <MenuButtonWrapper
            key={page.name}
            id={`tab.menubutton.${page.name}`}
            selected={isMenuSelected(location.pathname, page.path)}
            to={page.path}
            href={page.path}
            underline="none"
            target="_blank"
          >
            <MenuIcons selected={isMenuSelected(location.pathname, page.path)}>
              {page.icon}
            </MenuIcons>
            <MenuLabel
              underline="none"
              selected={isMenuSelected(location.pathname, page.path)}
            >
              {page.name}
            </MenuLabel>
          </MenuButtonWrapper>
        </Stack>
      ))}
    </Stack>
  );
};

export default Tabs;

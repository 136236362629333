import axios from "./axios";

export const getCPOs = async () => {
  const response = await axios.get("/1/admin/cpos");
  return response;
};

export const getCPODetails = async (id) => {
  const response = await axios.get(`/1/admin/cpos/${id}`);
  return response;
};
import React, { useEffect } from "react";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box } from "@mui/material";
import {
  fetchSubscriptionPlans,
  selectAllSubscriptionPlansSlice,
} from "../../modules/subscriptionPlan/subscriptionPlanSlice";
import { propOr } from "ramda";
import { Wrapper, PageContainer } from "./SubscriptionPlanStyles";
import ButtonLink from "../../lib/components/ButtonLink";
import { useNavigate } from "react-router-dom";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import { renderAsCustom } from "../../lib/components/Table/Cell/CellRenderers";
import Skeleton from "@mui/material/Skeleton";

export const SubscriptionPlans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subscriptionPlans = useSelector(selectAllSubscriptionPlansSlice);
  const subscriptionLoadingStatus = useSelector(
    (state) => state.subscriptionPlans.subscriptionLoadingStatus
  );
  useEffect(() => {
    dispatch(fetchSubscriptionPlans());
  }, [dispatch]);

  const columns = [
    {
      label: "Name",
      id: "name",
      accessor: "name",
      cell: ({ row }) => {
        const planName = row.original.name;
        return (
          <ButtonLink
            tooltipText={planName}
            value={planName}
            id={row.original.name}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/plans/${row.original.id}`);
            }}
            href={`/plans/${row.original.id}`}
          />
        );
      },
    },
    {
      label: "Plan value",
      id: "price",
      accessorFn: (row) => {
        let planValue = propOr("-", "price", row);
        // return `$ ${planValue}`;
        if (planValue == undefined || planValue == null || planValue == 0) {
          return "-";
        } else {
          return `$ ${planValue}`;
        }
      },
    },
    {
      label: "Fleet",
      id: "fleet_name",
      cell: ({ row }) => {
        let fleetName = propOr("-", "name", row.original.fleet);
        return (
          <ButtonLink
            tooltipText={fleetName}
            value={fleetName}
            id={row.original.name}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/fleets/${row.original.fleet_id}`);
            }}
          />
        );
      },
      accessorFn: (row) => {
        let fleetName = propOr("-", "name", row.fleet);
        return fleetName;
      },
      sortingFn: (rowA, rowB) => {
        const a = propOr("-", "name", rowA.original.fleet);
        const b = propOr("-", "name", rowB.original.fleet);
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "City",
      accessor: "city",
      id: "city",
    },
    {
      label: "Valid Days",
      id: "valid_hours",
      accessorFn: (row) => {
        let validDays = propOr("-", "valid_hours", row);
        let finalValidDays = validDays ? `${validDays / 24}` : "-";
        return `${finalValidDays}`;
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.valid_hours;
        const b = rowB.original.valid_hours;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Energy Limit",
      id: "energy_limit",
      accessorFn: (row) => {
        let energyLimit = propOr("-", "energy_limit", row);
        return `${energyLimit} kWh`;
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.energy_limit;
        const b = rowB.original.energy_limit;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Is Active",
      accessor: "active",
      id: "active",
      accessorFn: (row) => {
        let active = propOr("-", "active", row);
        if (active === true) return "Yes";
        else {
          return "No";
        }
      },
    },
    {
      label: "Is Display",
      accessor: "display",
      id: "display",
      accessorFn: (row) => {
        let display = propOr("-", "display", row);
        if (display === true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    // {
    //   label: "Overage usage",
    //   id: "cost",
    //   accessorFn: (row) => {
    //     let cost = propOr("-", "cost", row.overage_pricing);
    //     return cost;
    //   },
    // },
  ];

  const reactTableColumns = toReactTableColumns(columns);
  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          color: "#22093F",
          fontWeight: "bold",
          paddingTop: "30px",
        }}
      >
        Subscription Plans
      </Typography>
      {subscriptionLoadingStatus === "Loading" ? (
        <Box sx={{ marginTop: "-8%" }}>
          <Skeleton animation="wave" width={"90%"} height={900} />
        </Box>
      ) : (
        <PageContainer>
          <GenericTable data={subscriptionPlans} columns={reactTableColumns} />
        </PageContainer>
      )}
    </Wrapper>
  );
};

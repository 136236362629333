import React, { useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditDetailsFormDialog from "../../lib/components/EditDetailsModal";
import {
  selectChargingStationDetail,
  updateStationsDetails,
} from "../../modules/chargingStation/chargingStationSlice";
import { TextInputField } from "../../lib/components/TextField";
import { CheckBox } from "@mui/icons-material";

const EditStationDetails = ({ open, closeModal }) => {
  const stationDetails = useSelector(selectChargingStationDetail);
  const dispatch = useDispatch();
  const [modifiedStationDetails, setmodifiedStationDetails] =
    useState(stationDetails);
  const [error, setError] = useState({});

  const onSubmit = () => {
    let errors = {};
    if (!modifiedStationDetails.station_name) {
      errors.station_name = "Station name is required";
    }
    if (!modifiedStationDetails.state) {
      errors.state = "State  required";
    }
    if (!modifiedStationDetails.city) {
      errors.city = "City required";
    }
    if (!modifiedStationDetails.street_address) {
      errors.street_address = "Street address is required";
    }
    if (!modifiedStationDetails.postal_code) {
      errors.postal_code = "Postal code is required";
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
    } else {
      setError({});
    }
    if (
      modifiedStationDetails.station_name &&
      modifiedStationDetails.state &&
      modifiedStationDetails.city &&
      modifiedStationDetails.street_address &&
      modifiedStationDetails.postal_code
    ) {
      const requestData = {
        station_name: modifiedStationDetails.station_name,
        state: modifiedStationDetails.state,
        city: modifiedStationDetails.city,
        street_address: modifiedStationDetails.street_address,
        postal_code: modifiedStationDetails.postal_code,
        opening_times: {
          regular_hours: modifiedStationDetails.opening_times?.regular_hours,
          twenty_four_seven:
            modifiedStationDetails.opening_times?.twenty_four_seven,
        },
        direction: modifiedStationDetails.direction,
      };

      dispatch(
        updateStationsDetails({
          id: stationDetails?.id,
          data: requestData,
          successCB: () => {
            closeModal(!open);
          },
          errorCB: (err) => {
            console.log(err);
          },
        })
      );
    }
  };
  return (
    <EditDetailsFormDialog
      width="300px"
      open={open}
      setOpen={closeModal}
      title="Edit station details"
      onSubmit={onSubmit}
      content={
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "5px",
            }}
          >
            <Box>
              <TextInputField
                id="station_name"
                name="station_name"
                label={"Station Name"}
                value={modifiedStationDetails.station_name}
                onSetItem={(newValue) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    station_name: newValue,
                  });
                  setError((prevError) => ({ ...prevError, station_name: "" }));
                }}
                isRequired
              />
              {error.station_name && (
                <Typography sx={{ color: "red" }}>
                  {error.station_name}
                </Typography>
              )}
            </Box>
            <Box>
              <TextInputField
                id="state"
                name="state"
                label={"State"}
                value={modifiedStationDetails.state}
                onSetItem={(newValue) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    state: newValue,
                  });
                  setError((prevError) => ({ ...prevError, state: "" }));
                }}
                isRequired
              />
              {error.state && (
                <Typography sx={{ color: "red" }}>{error.state}</Typography>
              )}
            </Box>

            <Box>
              <TextInputField
                id="city"
                name="city"
                label={"City"}
                value={modifiedStationDetails.city}
                onSetItem={(newValue) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    city: newValue,
                  });
                  setError((prevError) => ({ ...prevError, city: "" }));
                }}
                isRequired
              />
              {error.city && (
                <Typography sx={{ color: "red" }}>{error.city}</Typography>
              )}
            </Box>
            <Box>
              <TextInputField
                id="street_address"
                name="street_address"
                label={"Street Address"}
                value={modifiedStationDetails.street_address}
                onSetItem={(newValue) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    street_address: newValue,
                  });
                  setError((prevError) => ({
                    ...prevError,
                    street_address: "",
                  }));
                }}
                isRequired
              />
              {error.street_address && (
                <Typography sx={{ color: "red" }}>
                  {error.street_address}
                </Typography>
              )}
            </Box>
            <Box>
              <TextInputField
                id="postal_code"
                name="postal_code"
                label={"Postal Code"}
                value={modifiedStationDetails.postal_code}
                onSetItem={(newValue) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    postal_code: newValue,
                  });
                  setError((prevError) => ({ ...prevError, postal_code: "" }));
                }}
                isRequired
              />
              {error.postal_code && (
                <Typography sx={{ color: "red" }}>
                  {error.postal_code}
                </Typography>
              )}
            </Box>
            {/* new */}

            <Box>
              <TextInputField
                id="direction"
                name="direction"
                label={"Direction"}
                value={modifiedStationDetails.direction}
                onSetItem={(newValue) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    direction: newValue,
                  });
                }}
              />
            </Box>
            <Box>
              <Checkbox
                id="opening_times"
                name="opening_times"
                checked={
                  modifiedStationDetails.opening_times?.twenty_four_seven ===
                  true
                }
                onChange={(event) => {
                  setmodifiedStationDetails({
                    ...modifiedStationDetails,
                    opening_times: {
                      ...modifiedStationDetails.opening_times,
                      twenty_four_seven: event.target.checked,
                    },
                  });
                }}
              />
              <label htmlFor="opening_times">24 Hours</label>
            </Box>
          </Box>
        </>
      }
    ></EditDetailsFormDialog>
  );
};

export default EditStationDetails;

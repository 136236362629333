import React, { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditDetailsFormDialog from "../../lib/components/EditDetailsModal";
import { Typography } from "@mui/material";
import { propOr } from "ramda";
import {
  updateDetailChargingSessions,
  selectDetailChargingSession,
} from "../../modules/chargingSession/chargingSessionSlice";
import { TextInputField } from "../../lib/components/TextField";

const EditSessionDetailsModal = ({ open, closeModal }) => {
  const dispatch = useDispatch();
  const sessionDetails = useSelector(selectDetailChargingSession);
  const [note, setNote] = useState(propOr("-", "notes", sessionDetails));

  const onSubmit = async () => {
    const id = sessionDetails?.id;
    const requestData = {
      notes: note,
    };
    dispatch(
      updateDetailChargingSessions({
        id,
        data: requestData,
        successCB: () => {
          closeModal(!open);
        },
        errorCB: (err) => {
          console.log(err);
        },
      })
    );
  };

  return (
    <EditDetailsFormDialog
      width="300px"
      height="150px"
      open={open}
      setOpen={closeModal}
      title="Edit session details"
      onSubmit={onSubmit}
      content={
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "5px",
              height: "80px",
            }}
          >
            <Box>
              <TextInputField
              
                id="note"
                fullWidth
                multiline
                name="note"
                label={"Note"}
                value={note}
                onSetItem={(newValue) => {
                  setNote(newValue);
                }}
                
              />
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {/* {error.email ? error.email : null} */}
              </Typography>
            </Box>
          </Box>
        </>
      }
    ></EditDetailsFormDialog>
  );
};

export default EditSessionDetailsModal;

import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")({
  backgroundColor: "#d7dde7",
  display: "flex",
  width: "100%",
  height: "100vh",
  color: "#ffffff",
  flexDirection: "column",
});

export const PageContainer = styled("div")({
  height: "100vh",
  overflowY: "auto",
  overflowX: "hidden",
});

import { createColumnHelper } from "@tanstack/react-table";
import { defaultTo, isNil, map, prop } from "ramda";
import { renderAsString } from "./Cell/CellRenderers";

export const defaultColumnStyle = {
  flex: "auto",
  minSize: 90,
  size: 100,
  textAlignment: "left",
};

const columnHelper = createColumnHelper();

export const toReactTableColumns = (columns) =>
  map((column) => {
    if (isNil(column.columns)) {
      return columnHelper.accessor(column.accessor, {
        ...column,
        header: defaultTo(column.accessor, prop("label", column)),
        size: defaultTo(defaultColumnStyle.size, prop("width", column)),
        cell: column?.cell || defaultTo(renderAsString, column.renderType),
        textAlign: defaultTo(
          defaultColumnStyle.textAlignment,
          prop("align", column)
        ),
      });
    }
  }, columns);

import { styled } from "@mui/material/styles";
import { isEmpty, isNil } from "ramda";
import ButtonLink from "../../ButtonLink";

const WidthCellWrapper = styled("div")({
  width: "auto",
  color: "black",
  flexDirection: "column",
  padding: "0 1px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const renderAsString = ({ getValue, cell, column }) => {
  return (
    <WidthCellWrapper
      id={cell.id}
      title={getValue()}
      style={{
        textAlign: column.columnDef.textAlign,
      }}
    >
      { isNil(getValue()) || isEmpty(getValue()) ? '-' : getValue()}
    </WidthCellWrapper>
  );
};

export const renderAsLink =
  (onClickLink) =>
  ({ getValue, cell }) =>
    (
      <WidthCellWrapper id={cell.id}>
        <ButtonLink
          value={getValue()}
          id={cell.id}
          conClick={() => {
            onClickLink(cell);
          }}
        />
      </WidthCellWrapper>
    );

export const renderAsCustom =
  (CustomComponent) =>
  ({ getValue, cell, column, row }) =>
    (
      <WidthCellWrapper id={column.id}>
        <CustomComponent
          value={getValue()}
          row={row}
          id={cell.id}
          column={column}
        />
      </WidthCellWrapper>
    );

import axios from "./axios";

export const getChargingSessions = async (userId) => {
  const response = await axios.get("/1/admin/sessions", { params: { userId } });
  return response;
};

export const DetailChargingSessions = async (sessionId) => {
  const response = await axios.get(`/1/admin/sessions/${sessionId}`);

  return response;
};

export const updateChargingSessionDetails = async (
  id,
  data,
  successCB,
  errorCB
) => {
  return await axios.patch(`/1/admin/sessions/${id}`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error.response.data);
      throw new Error(error.response);
    }
  );
};

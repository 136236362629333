import axios from "./axios";

export const getSubscriptionPlanList = async (fleet_id) => {
  const response = await axios.get("/1/admin/plans", { params: { fleet_id } });
  return response;
};

export const getSubscriptionPlanDetails = async (id) => {
  const response = await axios.get(`/1/admin/plans/${id}`);
  return response;
};

export const updateSubscriptionPlanDetail = async (
  id,
  data,
  successCB,
  errorCB
) => {
  return await axios.patch(`/1/admin/fleets/${id}/subscription-plans`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error.response.data);
      throw new Error(error.response);
    }
  );
};

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import { Box } from "@mui/material";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import { propOr } from "ramda";
import { useParams } from "react-router-dom";
import {
  PageContainer,
  Wrapper,
} from "../ChargingSessions/chargingSessionStyles";
import ButtonLink from "../../lib/components/ButtonLink";
import {
  fetchSubscriptionPlanswithFleetID,
  selectAllSubscriptionPlansSlice,
  selectAllSubscriptionPlanswithFleetIDSlice,
} from "../../modules/subscriptionPlan/subscriptionPlanSlice";
import EditIcon from "@mui/icons-material/Edit";
import { EditSubscriptionPlan } from "../SubscriptionPlans/EditSubscriptionPlan";
export const FleetPlanView = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptionPlans = useSelector(selectAllSubscriptionPlansSlice);
  const subscriptionPlanswithFleetId = useSelector(
    selectAllSubscriptionPlanswithFleetIDSlice
  );

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchSubscriptionPlanswithFleetID(id));
  }, [dispatch, id]);
  const column = [
    {
      label: "Name",
      id: "name",
      accessor: "name",
      cell: ({ row }) => {
        const planName = row.original.name;
        return (
          <ButtonLink
            tooltipText={planName}
            value={planName}
            id={row.original.name}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/plans/${row.original.id}`);
            }}
            href={`/plans/${row.original.id}`}
          />
        );
      },
    },
    {
      label: "Plan value",
      id: "price",
      accessorFn: (row) => {
        let planValue = propOr("-", "price", row);
        // return `$ ${planValue}`;
        if (planValue == undefined || planValue == null || planValue == 0) {
          return "-";
        } else {
          return `$ ${planValue}`;
        }
      },
    },
    {
      label: "City",
      accessor: "city",
      id: "city",
    },
    {
      label: "Valid Days",
      id: "valid_hours",
      accessorFn: (row) => {
        let validDays = propOr("-", "valid_hours", row);
        let finalValidDays = `${validDays}` / 24;
        return `${finalValidDays}`;
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.valid_hours;
        const b = rowB.original.valid_hours;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Energy Limit",
      id: "energy_limit",
      accessorFn: (row) => {
        let energyLimit = propOr("-", "energy_limit", row);
        return `${energyLimit}`;
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.energy_limit;
        const b = rowB.original.energy_limit;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "Is Active",
      accessor: "active",
      id: "active",
      accessorFn: (row) => {
        let active = propOr("-", "active", row);
        if (active === true) return "Yes";
        else {
          return "No";
        }
      },
    },
    {
      label: "Is Display",
      accessor: "display",
      id: "display",
      accessorFn: (row) => {
        let display = propOr("-", "display", row);
        if (display === true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    // {
    //   label: "Overage usage",
    //   id: "cost",
    //   accessorFn: (row) => {
    //     let cost = propOr("-", "cost", row.overage_pricing);
    //     return cost;
    //   },
    // },
  ];
  const reactTableColumns = toReactTableColumns(column);
  return (
    <Wrapper sx={{ background: "white" }}>
      <Box sx={{ zIndex: 5, color: "black" }}>
        <EditIcon
          sx={{ fontSize: 30, cursor: "pointer" }}
          onClick={() => setOpenModal(true)}
        />
      </Box>
      <PageContainer sx={{ background: "white", marginTop: "-20px" }}>
        <GenericTable
          data={subscriptionPlanswithFleetId}
          columns={reactTableColumns}
        />
        {openModal && (
          <>
            <EditSubscriptionPlan
              open={openModal}
              closeModal={setOpenModal}
              fleetId={id}
            />
          </>
        )}
      </PageContainer>
    </Wrapper>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getChargingStations,getChargingStationsDetails,updateStationDetails } from "../../api/chargingstations";

const initialState = {
  status: "idle",
  chargingStations: [],
  chargingStationDetails:"",
  chargingStationLoading:"",
  singleStationLoading:""
};

export const fetchChargingStations = createAsyncThunk(
  "/fetchChargingStations",
  async () => {
    const response = await getChargingStations();
    return response.data;
  }
);

export const fetchChargingStationsDetails = createAsyncThunk(
  "/fetchChargingStationsDetails",
  async (id) => {
    const response = await getChargingStationsDetails(id);
    return response.data;
  }
);

export const updateStationsDetails = createAsyncThunk(
  "/updateStationDetails",
  async({id,data,successCB,errorCB}) => {
    const response = await updateStationDetails(id,data,successCB,errorCB);
    successCB(response.data)
    return response.data
  }
)

const chargingStationSlice = createSlice({
  name: "chargingStations",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
      
    },
    clearStationDetails:(state) =>{
      state.chargingStationDetails = "";

    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChargingStations.pending, (state, action) => {
        state.status = "loading";
        state.chargingStationLoading = 'Loading';
      })
      .addCase(fetchChargingStations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chargingStations = action.payload.data;
        state.chargingStationLoading = 'succeeded';

      })
      .addCase(fetchChargingStations.rejected, (state, action) => {
        state.status = "failed";
        state.chargingStationLoading = 'failed';

      })
      .addCase(fetchChargingStationsDetails.pending, (state, action) => {
        state.status = "loading";
        state.singleStationLoading = 'loading';

      })
      .addCase(fetchChargingStationsDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleStationLoading = "succeeded";
        state.chargingStationDetails = action.payload.data;
        

      })
      .addCase(fetchChargingStationsDetails.rejected, (state, action) => {
        state.status = "failed";
        state.singleStationLoading = 'failed';

      })
      .addCase(updateStationsDetails.pending, (state,action) =>{
        state.status = "loading";
      })
      .addCase(updateStationsDetails.fulfilled, (state,action) =>{
        const index = state.chargingStations.findIndex(
          (item) => item.id === action.payload.id
        );
        state.chargingStations[index] = action.payload;
        state.chargingStationDetails = action.payload;
        state.status = "succeeded";
      })
      .addCase(updateStationsDetails.rejected, (state,action) =>{
        state.status = "failed"
      })
  },
});

export const { updateState,clearStationDetails } = chargingStationSlice.actions;

export default chargingStationSlice.reducer;


export const selectAllChargingStations = (state) => {
  return state.chargingStations.chargingStations;
};
export const selectChargingStationDetail = (state) => {
  return state.chargingStations.chargingStationDetails;
};

import axios from "./axios";

export const getUserList = async () => {
  const response = await axios.get("/1/admin/users");
  return response;
};

export const getUserDetails = async (userId) => {
  const response = await axios.get(`/1/admin/user/${userId}`);
  return response;
};

export const updateUserDetails = async (userId, data, successCB, errorCB) => {
  return await axios.patch(`/1/admin/users/${userId}`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error.response.data);
      throw new Error(error.response);
    }
  );
};

export const updateUserDetailsFleet = async (
  userId,
  data,
  successCB,
  errorCB
) => {
  return await axios.patch(`/1/admin/users/${userId}/fleets`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error);
      throw new Error(error.response);
    }
  );
};

export const createUserDetailsFleet = async (
  userId,
  data,
  successCB,
  errorCB
) => {
  return await axios.post(`/1/admin/users/${userId}/fleets`, data).then(
    (res) => {
      successCB(res.data);
      return res;
    },
    (error) => {
      errorCB(error);
      throw new Error(error.response);
    }
  );
};

import React, { useEffect } from "react";
import { Wrapper } from "../../pages/User/UserStyles";
import { Grid, Typography, Box, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CardView from "../../lib/components/CardView";
import {  prop } from "ramda";
import Skeleton from "@mui/material/Skeleton";

import {
  selectFleetDetails,
  fetchFleetDetails,
  clearFleetDetails,
} from "../../modules/fleets/fleetSlice";
import AccordionTable from "../../lib/components/AccordianTable/Index";
import { UserFleet } from "./UserFleet";
import { FleetPlanView } from "./FleetPlanView";

export const FleetDetails = () => {
  const dispatch = useDispatch();
  const fleetDetails = useSelector(selectFleetDetails);
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchFleetDetails(id));
  }, [dispatch, id]);
useEffect(()=>{
  dispatch(clearFleetDetails())
},[])
 
  const singleFleetsLoadingStatus = useSelector(
    (state) => state.fleets.singleFleetsLoadingStatus
  );
  return (
    <Wrapper sx={{ marginTop: "60px" }}>
      <Grid container spacing={2}>
        {singleFleetsLoadingStatus === "loading" && fleetDetails.length === 0 ?(
             <Box>
             <Grid container spacing={2}>
               <Grid item xs={4} sx={{ height: "100%" }}>
                 <CardView
                   padding={"20px"}
                   width={"27vw"}
                   isboxShadow={true}
                   title={
                     <Box sx={{ display: "flex", justifyContent: "center" }}>
                       <Skeleton variant="circular" width={100} height={100} />
                     </Box>
                   }
                   content={<Skeleton height={800} />}
                 />
               </Grid>
               <Grid item xs={8}>
                 <Box sx={{ marginTop: "-4%", marginLeft: "25%" }}>
                   <Skeleton height={130} width={750} />
                   <Skeleton height={130} width={750} />
                 </Box>
               </Grid>
             </Grid>
           </Box>
        ) : (
          <>
            <Grid item xs={4}>
              <CardView
                padding={"20px"}
                width={"30vw"}
                height={"50vh"}
                isboxShadow={true}
                title={
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      borderBottom: "1px solid grey",
                      marginTop: "10px",
                      fontSize: "20px",
                    }}
                  >
                   {fleetDetails?.id}
                  </Typography>
                }
                content={
                  <Grid sx={{ marginLeft: "5%", marginTop: "5%" }}>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Fleet Name
                        </Typography>
                        <Typography>{fleetDetails?.name ? fleetDetails.name : "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          Fleet Segment
                        </Typography>
                        <Typography>{prop('fleet_segment',fleetDetails)? fleetDetails.fleet_segment:'-' }</Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"flex-start"}
                      sx={{ paddingBottom: "15px" }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          </Typography >
                          <Typography sx={{
                            fontWeight: "bold",
                          }}>Fleet Identifier </Typography>
                          <Typography>{prop("fleet_identifier",fleetDetails)? fleetDetails?.fleet_identifier:"-"}</Typography>
                          
                          </Box>
                          </Stack>
                  </Grid>
                }
              ></CardView>
            </Grid>
            <Grid item xs={8}>
              <AccordionTable
                summary={<Typography variant="h6"> User Fleets</Typography>}
                details={
                  <>
                    <Grid sx={{ width: "90%", background: "white" }}>
                      <UserFleet />
                    </Grid>
                    
                  </>
                }
              />
              <AccordionTable
                summary={<Typography variant="h6"> Fleet Plan</Typography>}
                details={
                  <>
                    <Grid sx={{ width: "90%", background: "white" }}>
                      <FleetPlanView />
                    </Grid>
                    
                  </>
                }
              />
            </Grid>
          </>
          
        )}
      </Grid>
    </Wrapper>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUser } from "../../api/dummy";
const initialState={
    user:undefined,
    status:'idle'
}

export const fetchUser = createAsyncThunk("/fetchUser", async () => {
  const response = await getUser();
  return response.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateState: (state) => {
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { updateState } = authSlice.actions;
export default authSlice.reducer;

export const myuser = (state) => state.auth.user;

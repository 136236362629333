import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GenericTable } from "../../lib/components/Table/GenericTable";
import {
  fetchChargingStations,
  selectAllChargingStations,
} from "../../modules/chargingStation/chargingStationSlice";
import { Typography, Box } from "@mui/material";
import { Wrapper, PageContainer } from "./chargingStationStyles";
import { propOr, length } from "ramda";
import { toReactTableColumns } from "../../lib/components/Table/GenericTableUtils";
import { renderAsCustom } from "../../lib/components/Table/Cell/CellRenderers";
import ButtonLink from "../../lib/components/ButtonLink";
import Skeleton from "@mui/material/Skeleton";

export const ChargingStations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chargingStations = useSelector(selectAllChargingStations);
  const stationLoadingStatus = useSelector(
    (state) => state.chargingStations.chargingStationLoading
  );
  useEffect(() => {
    dispatch(fetchChargingStations());
  }, [dispatch]);
  const columns = [
    {
      label: "Station Name",
      accessor: "station_name",
      id: "station_name",
      cell: ({ row }) => {
        const name = row.original?.station_name
          ? row.original?.station_name
          : "-";
        return (
          <ButtonLink
            value={name}
            id={row?.original?.id}
            tooltipText={row?.original?.station_name}
            textDecoration={"none"}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/stations/${row?.original?.id}`);
            }}
            href={`/stations/${row?.original?.id}`}
          />
        );
      },
      sortingFn: (rowA, rowB) => {
        const a = rowA.original.station_name;
        const b = rowB.original.station_name;
        return a > b ? 1 : a < b ? -1 : 0;
      },
    },
    {
      label: "City",
      accessor: "city",
      id: "city",
    },

    {
      label: "State",
      accessor: "state",
      id: "state",
    },
    {
      label: "Address",
      accessor: "street_address",
      id: "street_address",
    },
    {
      label: "CPO Network",
      id: "cpo_name",
      accessorFn: (row) => {
        let subscriptionPlan = propOr("-", "name", row.cpo);
        return subscriptionPlan;
      },
    },
    {
      label: "Total number of EVSE(Available / Total) ",
      id: "total_number_of_EVSE ",
      accessorFn: (row) => {
        let TotalEVSE = length(row.evse);
        let availableEVSE = row.evse.filter(
          (item) => item.status === "AVAILABLE"
        ).length;
        return `${availableEVSE}/${TotalEVSE}`;
      },
    },
    {
      label: "Direction",
      id: "direction",
      accessorFn: (row) => {
        let direction = propOr("-", "direction", row);
        return direction;
      },
    },
  ];

  const reactTableColumns = toReactTableColumns(columns);

  return (
    <Wrapper>
      <Typography
        variant="h4"
        style={{
          color: "#22093F",
          fontWeight: "bold",
          paddingTop: "30px",
        }}
      >
        Charging Stations
      </Typography>
      {stationLoadingStatus === "Loading" ? (
        <Box sx={{ marginTop: "-8%" }}>
          <Skeleton animation="wave" width={"90%"} height={900} />
        </Box>
      ) : (
        <PageContainer>
          <GenericTable data={chargingStations} columns={reactTableColumns} />
        </PageContainer>
      )}
    </Wrapper>
  );
};

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import LocalStorageService from "../lib/utils/LocalStorageService";

function PublicOutlet() {
  const isAuthenticated = () => {
    return LocalStorageService.getAccessToken();
  };
  return isAuthenticated() ? <Navigate to="/users" /> : <Outlet />;
}

export default PublicOutlet;

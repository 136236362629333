import axios from "axios";

const getUser = async () => {
  return await axios.get("https://dummyjson.com/users");
};

const getStation = async () => {
  return await axios.get("https://dummyjson.com/users"); //newly added
};
export { getUser };
export { getStation };

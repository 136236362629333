import axios from "axios";
import LocalStorageService from "../lib/utils/LocalStorageService";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
});

// Request Interceptor
instance.interceptors.request.use(
  (config) => {
    if (config.headers === undefined) {
      config.headers = {};
    }
    const token = LocalStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response Interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      originalRequest.url === `/1/admin/auth/refresh-token`
    ) {
      LocalStorageService.clearToken();
      window.location.href = "/";
      return Promise.reject(error);
    }

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = LocalStorageService.getRefreshToken();
      const res = await instance.post(`/1/admin/auth/refresh-token`, {
        refresh_token: refreshToken,
      });
      if (res.status === 200 || res.status === 201) {
        const { access_token, refresh_token } = res.data.data;
        LocalStorageService.setToken(access_token, refresh_token);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + LocalStorageService.getAccessToken();
        return instance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;

import React, { useEffect, useState } from "react";
import EditDetailsFormDialog from "../../lib/components/EditDetailsModal";
import {
  fetchSubscriptionPlans,
  selectAllSubscriptionPlansSlice,
  selectAllSubscriptionPlanswithFleetIDSlice,
  updateSubscriptionsPlanDetail,
} from "../../modules/subscriptionPlan/subscriptionPlanSlice";

import { Typography, Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import MultipleSelectChip from "../../lib/components/MultiSelectField";
export const EditSubscriptionPlan = ({ open, closeModal, fleetId }) => {
  const plans = useSelector(selectAllSubscriptionPlansSlice);
  const dispatch = useDispatch();
  const subscriptionPlanswithFleetId = useSelector(
    selectAllSubscriptionPlanswithFleetIDSlice
  );
  const [listSubscriptionPlans, setListSubscriptionPlans] = useState(
    subscriptionPlanswithFleetId
  );
  const [error, setError] = useState({});
  useEffect(() => {
    const fleet_id = "unassociated";
    dispatch(fetchSubscriptionPlans(fleet_id));
    // eslint-disable-next-line
  }, []);

  const onSubmit = async () => {
    const requestData = {
      subscription_plans: listSubscriptionPlans.map((option) => option.id),
    };
    dispatch(
      updateSubscriptionsPlanDetail({
        id: fleetId,

        data: requestData,
        successCB: () => {
          closeModal(!open);
        },
        errorCB: (err) => {
          console.log(err);
        },
      })
    );
  };

  return (
    <EditDetailsFormDialog
      width="350px"
      height="35vh"
      open={open}
      setOpen={closeModal}
      title="Edit Subscription Plan"
      onSubmit={onSubmit}
      content={
        <Box sx={{ marginLeft: "-9px" }}>
          <MultipleSelectChip
            id="subscription_plan"
            name="subscription_plan"
            label="Subscription plan"
            value={listSubscriptionPlans}
            options={[...plans, ...subscriptionPlanswithFleetId]}
            renderValue={listSubscriptionPlans}
            onSetItem={(newValue) => {
              setListSubscriptionPlans(newValue);
              setError((prevError) => ({
                ...prevError,
                subscriptionPlan: "",
              }));
            }}
            width="237px"
          />
          {error.subscriptionPlan && (
            <Typography sx={{ color: "red", marginLeft: "9px" }}>
              {error.subscriptionPlan}
            </Typography>
          )}
        </Box>
      }
    />
  );
};

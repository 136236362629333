import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import TextField from "@mui/material/TextField";

export const DatePickerField = ({
  label,
  id,
  name,
  onSetItem,
  value,
  isRequired,
  isDisabled,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateTimePicker"]}>
        <DateTimeField
          label={label}
          id={id}
          value={dayjs(value)}
          format={"DD/MM/YYYY HH:mm"}
          name={name}
          size="small"
          onChange={(newValue) => {
            const formattedValue = dayjs(newValue).format("MM/DD/YYYY HH:mm");

            onSetItem(formattedValue);
          }}
          disabled={isDisabled}
          renderInput={(params) => <TextField {...params} size="small" />}
          slotProps={{
            textField: {
              size: "small",
              error: false,
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditDetailsFormDialog from "../../lib/components/EditDetailsModal";
import { TextInputField } from "../../lib/components/TextField";
import { Box, Checkbox, Typography } from "@mui/material";
import { addFleet } from "../../modules/fleets/fleetSlice";
import { SelectField } from "../../lib/components/SelectField";
import MultipleSelectChip from "../../lib/components/MultiSelectField";
import {
  fetchSubscriptionPlans,
  selectAllSubscriptionPlansSlice,
} from "../../modules/subscriptionPlan/subscriptionPlanSlice";

function AddFleet({ open, closeModal }) {
  const dispatch = useDispatch();
  const [fleetName, setFleetName] = useState("");
  const [fleetSegment, setFleetSegment] = useState([]);
  const [fleetIdentifier, setFleetIdentifier] = useState("");
  const [displayChecked, setDisplayChecked] = useState(false);
  const [voucherChecked, setVoucherChecked] = useState(false);
  const [error, setError] = useState({});
  const [listSubscriptionPlans, setListSubscriptionPlans] = useState([]);
  const subscriptionPlans = useSelector(selectAllSubscriptionPlansSlice);
  const fleetSegmentOptions = [
    {
      name: "RAC",
      value: "RAC",
    },
    {
      name: "TNC",
      value: "TNC",
    },
  ];
  const onSubmit = () => {
    let errors = {};
    if (!fleetName) {
      errors.fleetName = "Name is required";
    }

    if (fleetSegment.length === 0) {
      errors.fleetSegment = "Fleet segment is required";
    }

    if (!displayChecked) {
      errors.displayCheckbox = "Checkbox required";
    }
    if (!voucherChecked) {
      errors.voucherCheckbox = "Checkbox required";
    }
    if (Object.keys(errors).length > 0) {
      setError(errors);
    } else {
      setError({});
    }

    if (fleetName && fleetSegment && displayChecked && voucherChecked) {
      const requesData = {
        name: fleetName,
        fleet_segment: fleetSegment,
        fleet_identifier: fleetIdentifier,
        display: displayChecked,
        voucher_support: voucherChecked,
        subscription_plans: listSubscriptionPlans.map((option) => option.id),
      };
      dispatch(
        addFleet({
          data: requesData,
          successCB: () => {
            closeModal(!open);
          },
          errorCB: (err) => {
            console.log(err);
          },
        })
      );
    }
  };

  useEffect(() => {
    const fleet_id = "unassociated";
    dispatch(fetchSubscriptionPlans(fleet_id));
  }, []);
  return (
    <EditDetailsFormDialog
      width="300px"
      open={open}
      setOpen={closeModal}
      onSubmit={onSubmit}
      title="Create fleet"
      content={
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "5px",
          }}
        >
          <Box>
            <TextInputField
              id="name"
              name="Name"
              label="Name"
              value={fleetName}
              onSetItem={(value) => {
                setFleetName(value);
                setError((prevError) => ({ ...prevError, fleetName: "" }));
              }}
              isRequired
            />
            {error.fleetName && (
              <Typography sx={{ color: "red" }}>{error.fleetName}</Typography>
            )}
          </Box>
          <Box sx={{ marginLeft: "-9px" }}>
            <SelectField
              id="fleet_segment"
              name="fleet_segment"
              label="Fleet segment *"
              value={fleetSegment}
              options={fleetSegmentOptions}
              onSetItem={(item) => {
                setFleetSegment(item.value);
                setError((prevError) => ({
                  ...prevError,
                  fleetSegment: "",
                }));
              }}
              width="237px"
            />
            {error.fleetSegment && (
              <Typography sx={{ color: "red", marginLeft: "9px" }}>
                {error.fleetSegment}
              </Typography>
            )}
          </Box>
          <Box sx={{ marginLeft: "-9px" }}>
            <MultipleSelectChip
              id="subscription_plan"
              name="subscription_plan"
              label="Subscription plan "
              value={listSubscriptionPlans}
              options={subscriptionPlans}
              onSetItem={(newValue) => {
                setListSubscriptionPlans(newValue);
                setError((prevError) => ({
                  ...prevError,
                  subscriptionPlan: "",
                }));
              }}
              width="237px"
            />
          </Box>
          <Box>
            <TextInputField
              id="fleet_identifier"
              name="fleet_identifier"
              label="Fleet identifier"
              value={fleetIdentifier}
              onSetItem={(value) => {
                setFleetIdentifier(value);
              }}
            />
          </Box>
          <Box>
            <Checkbox
              id="display"
              name="display"
              checked={displayChecked}
              onChange={(e) => {
                setDisplayChecked(e.target.checked);
                setError((prevError) => ({
                  ...prevError,
                  displayCheckbox: "",
                }));
              }}
            />

            <label htmlFor="opening_times">Display</label>
            {error.displayCheckbox && (
              <Typography sx={{ color: "red" }}>
                {error.displayCheckbox}
              </Typography>
            )}
          </Box>
          <Box>
            <Checkbox
              id="voucher_support"
              name="voucher_support"
              checked={voucherChecked}
              onChange={(e) => {
                setVoucherChecked(e.target.checked);
                setError((prevError) => ({
                  ...prevError,
                  voucherCheckbox: "",
                }));
              }}
            />

            <label htmlFor="opening_times">Voucher support</label>
            {error.voucherCheckbox && (
              <Typography sx={{ color: "red" }}>
                {error.voucherCheckbox}
              </Typography>
            )}
          </Box>
        </Box>
      }
    ></EditDetailsFormDialog>
  );
}

export default AddFleet;
